import { backEndApi } from '@root/libs';

const api = '/api/v1';
const limitationPath = '/user-limitation';
const limitationAPIs = {
  getUsersLimitation: (siteId) =>
    backEndApi.get(`${api}${limitationPath}/${siteId}`),
  updateUsersLimitation: (siteId, body) =>
    backEndApi.put(`${api}${limitationPath}/${siteId}`, body),
};
export default limitationAPIs;
