/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { makeStyles } from '@material-ui/styles';
import { authAPIs } from '@root/services';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { SignupCheckStatus } from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';

const useStyles = makeStyles(() => ({
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '332px',
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '192px',
  },
  verifyTitle: {
    fontWeight: '600',
    fontSize: '32px',
    color: '#091E42',
    marginTop: '17px',
  },
  verifyMessage: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#091E42',
    marginTop: '14px',
  },
  input: {
    '& > input': {
      marginTop: '13px',
      color: '#000',
      border: '1px solid #C1C7D0',
      height: '40px',
      fontSize: '14px',
      background: ' #F5F5F5',
      boxSizing: 'border-box',
      fontWeight: 500,
      borderRadius: '20px',
      padding: '0 10px 1px 18px',
      '&:focus': {
        border: '1px solid #FAD100',
        background: '#F5F5F5 !important',
      },
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
    '& > select': {
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
          boxShadow: '0 0 0 2px #fad10042 !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  otpInput: {
    width: '40px',
    height: '40px',
    margin: '48px 8px 20px',
    fontSize: '20px',
    textAlign: 'center',
    border: '1px solid #C1C7D0',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#FAD100',
      outline: 'none',
    },
  },
  resendOtp: {
    color: '#20A1FF',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'underline',
    '&:hover': {
      color: '#003366',
    },
    '&.disabled': {
      color: '#A5ADBA',
      cursor: 'not-allowed',
    },
  },
  backToSignUp: {
    marginTop: '150px',
    color: '#20A1FF',
    fontWeight: '400',
    fontSize: '14px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'underline',
    '&:hover': {
      color: '#003366',
    },
  },
  errorMessage: {
    fontSize: '14px',
    color: '#FF0000',
    textAlign: 'center',
    marginBottom: '12px',
  },
}));

const VerifyEmail = ({ setSignup }) => {
  const classes = useStyles();
  const location = useLocation();

  const email = location.state?.email;
  const { userId } = useParams(); // Get user_id from the route parameters

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRefs = useRef([]);
  useEffect(() => {
    if (isResendDisabled) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 30; // Reset timer for the next time
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, [isResendDisabled]);

  const handleSubmit = async (otpValue) => {
    setIsLoading(true);
    setIsResendDisabled(true);
    newRelicUtils.addPageAction(PAGE_ACTION.signup.verifyEmail, {
      userEmail: email,
      userId,
    });
    try {
      const response = await authAPIs.signupConfirm({
        userId,
        email,
        code: otpValue,
      });
      console.log('response', response);
      if (response.status === 200) {
        setErrorMessage('');
        setSignup(SignupCheckStatus.SUCCESS);
        navigate('/verify-success');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (response.errorCode === 'CodeMismatchException') {
          setErrorMessage(t('auth.forgotPassword.invalidCodeError'));
        } else if (response.errorCode === 'LimitExceededException') {
          setErrorMessage(t('auth.forgotPassword.limitExceededError'));
        } else {
          setErrorMessage(t('auth.forgotPassword.invalidCodeError'));
        }
      }
    } catch (error) {
      console.error('OTP verification failed:', error);
    }
    setIsLoading(false);
    setIsResendDisabled(false);
  };
  const handleChange = async (element, index) => {
    if (Number.isNaN(Number(element.value))) return;
    setErrorMessage('');
    const newOtp = [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ];
    setOtp(newOtp);

    // Focus the next input
    if (element.value !== '' && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (index === 5 && element.value !== '') {
      await handleSubmit(newOtp.join(''));
    }
  };
  const handleKeyDown = (e, index) => {
    setErrorMessage('');
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = async (e) => {
    e.preventDefault();

    // Get pasted data, limit to 6 characters, and split into an array
    const pasteData = e.clipboardData
      .getData('text')
      .slice(0, 6)
      .split('')
      .filter((char) => /^\d$/.test(char));

    // Construct the new OTP array by combining pasted data with the current OTP
    const newOtp = otp.map(
      (currentValue, index) => pasteData[index] || currentValue,
    );
    setOtp(newOtp);
    inputRefs.current[pasteData.length - 1]?.focus();

    // Automatically submit if all 6 characters are pasted
    if (pasteData.length === 6) {
      await handleSubmit(newOtp.join(''));
    }
  };

  const handleResendOtp = async () => {
    if (isResendDisabled) return;

    setErrorMessage('');
    await authAPIs.resendOtpEmail({ email });

    // Disable the resend button and start the timer
    setIsResendDisabled(true);
    setTimer(30); // Restart the countdown
  };

  const handleBackToSignUp = () => {
    navigate('/signup', { replace: true });
  };

  useEffect(() => {
    // Focus the first input on component mount
    inputRefs.current[0].focus();
  }, []);
  return (
    <>
      <form className={classes.signUpForm} autoComplete='off'>
        <span className={classes.verifyTitle}>
          {t('auth.signup.signupTitle')}
        </span>
        <span className={classes.verifyMessage}>
          {t('auth.verifyEmail.enterOTP')}
        </span>
        <div className={classes.otpContainer} onPaste={handlePaste}>
          {otp.map((item, index) => (
            <input
              // eslint-disable-next-line react/no-array-index-key
              key={`key_${index}`}
              type='text'
              maxLength='1'
              className={classes.otpInput}
              value={item}
              onChange={async (e) => {
                await handleChange(e.target, index);
              }}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => {
                inputRefs.current[index] = el;
              }}
            />
          ))}
        </div>
        {isLoading && <Spinner size='medium' />}
        {!isLoading && errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
        <div
          className={`${classes.resendOtp} ${
            isResendDisabled ? 'disabled' : ''
          }`}
          onClick={handleResendOtp}
        >
          {isResendDisabled ? `Resend OTP ( ${timer}s )` : 'Resend OTP'}
        </div>
        <div className={classes.backToSignUp} onClick={handleBackToSignUp}>
          {t('auth.verifyEmail.backToSignUp')}
        </div>
      </form>
    </>
  );
};

export default VerifyEmail;
