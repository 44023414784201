import Button from '@atlaskit/button';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  isSelectedButton: {
    backgroundColor: '#D1E5FF !important',
    border: '2px solid #D1E5FF !important',
    borderRadius: '5px !important',
    '& span': {
      color: '#0052CC !important',
      lineHeight: '2rem',
    },
  },
  defaultButton: {
    backgroundColor: '#fff !important',
    boxSizing: 'border-box',
    border: '2px solid rgba(0,0,0,0.12) !important',
    borderRadius: '5px !important',
    '& span': {
      lineHeight: '2rem',
    },
  },
}));

export default function ButtonCustom({
  isSelected,
  handleButtonClick,
  content,
}) {
  const classes = useStyles();
  return (
    <Button
      appearance='subtle'
      className={isSelected ? classes.isSelectedButton : classes.defaultButton}
      onClick={handleButtonClick}
    >
      {content}
    </Button>
  );
}
