/* eslint-disable no-underscore-dangle, no-unused-vars */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TooltipPrimitive } from '@atlaskit/tooltip';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import ProjectContentLoader from './ProjectContentLoader';
import { AuthContext } from '../AuthPages/AuthProvider';

const useStyles = makeStyles(() => ({
  main: {
    display: 'block',
    width: '100%',
    marginTop: '10rem',
    padding: '0 3rem',
    color: '#303952',
  },
  listContent: {
    width: '100%',
    margin: '2rem auto',
    marginBottom: '0',
  },
  listCard: {
    width: '100%',
    margin: '2rem 0',
    marginBottom: '0',
    borderRadius: '1rem',
    backgroundColor: 'whitesmoke',
    padding: '2rem 20px',
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    height: '400px',
  },
  icon: {
    visibility: 'hidden',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: '#10e810',
  },
  pinIcon: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    visibility: 'hidden',
    width: '30px',
    height: '30px',
    boxSizing: 'border-box',
    padding: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid transparent',
      borderRadius: '50%',
      backgroundColor: 'rgba(95,99,104,0.157)',
    },
    '&:hover + a': {
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
  },
  active: {
    visibility: 'visible',
  },
  navItem: {
    position: 'relative',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  navItem_: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    borderRadius: '15px',
    textDecoration: 'none',
    color: 'black',
    border: '1px solid #B9B9B9',
    backgroundColor: '#FAFAFA',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      border: 'none',
    },
  },
  item: {
    width: '100%',
    height: '100%',
  },
  titleItem: {
    fontSize: '17px',
    padding: '0 5px',
    margin: '1.5rem 0',
    boxSizing: 'border-box',
    width: '100%',
    '& h4': {
      margin: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  titleItemSolution: {
    margin: '-0.5rem 0 !important',
  },
  circleAvatar: {
    height: '200px',
    width: '200px',
    backgroundColor: '#ec6424',
    borderRadius: '50%',
    border: '1px solid transparent',
    overflow: 'hidden',
  },
  image: {
    width: '80%',
    height: '70%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  description: {
    width: '80%',
    margin: '0 auto',
    fontSize: '16px',
    padding: '0',
  },
  '@media screen and (min-width: 1900px)': {
    listCard: {
      height: '550px',
    },
    navItem: {
      maxWidth: '24%',
    },
    circleAvatar: {
      height: '200px',
      width: '200px',
    },
    titleItem: {
      fontSize: '25px',
    },
  },
  '@media screen and (max-width: 1900px)': {
    listCard: {
      height: '450px',
    },
    navItem: {
      maxWidth: '23%',
    },
    titleItem: {
      fontSize: '23px',
      margin: '0.5rem 0',
    },
    circleAvatar: {
      height: '170px',
      width: '170px',
    },
  },
  '@media screen and (max-width: 1600px)': {
    listCard: {
      height: '400px',
    },
    navItem: {
      maxWidth: '20%',
    },
    titleItem: {
      margin: '1rem 0',
      fontSize: '17px',
    },
    circleAvatar: {
      height: '150px',
      width: '150px',
    },
  },
  '@media screen and (max-width: 1400px)': {
    circleAvatar: {
      height: '120px',
      width: '120px',
    },
    navItem: {
      maxWidth: '23%',
    },
    description: {
      fontSize: '14px',
    },
  },
  '@media screen and (max-width: 1200px)': {
    listCard: {
      height: '350px',
    },
    circleAvatar: {
      height: '100px',
      width: '100px',
    },
    description: {
      fontSize: '14px',
    },
    titleItem: {
      margin: '0',
      fontSize: '15px',
    },
  },
  '@media screen and (max-width: 1000px)': {
    listCard: {
      height: '300px',
    },
    circleAvatar: {
      height: '90px',
      width: '90px',
    },
    titleItem: {
      margin: '0',
      fontSize: '13px',
    },
  },
}));

const ScreenLayout = (props) => {
  const { siteAdmin, listDescription, rootPath, keypage } = props;
  const classes = useStyles();
  const { siteList } = useSelector((appState) => appState.site);
  const { loadingMenu } = useSelector((appState) => appState.site);
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  const renderDescription = (key) => {
    const description1 = listDescription.find((dc) => dc.key === key);
    if (description1) {
      setDescription(description1);
    } else {
      setDescription('');
    }
  };

  const renderCard = (cards) => {
    if (keypage === 'admin') {
      return cards.map((ld, key) => (
        <div
          className={classes.navItem}
          style={{ width: `calc( 100% / ${cards.length} )` }}
          key={ld.key}
        >
          <NavLink
            end
            to={rootPath !== '/' ? `${rootPath}/${ld.key}` : `/${ld.key}`}
            className={classes.navItem_}
          >
            <div
              className={`${classes.item}`}
              onMouseMove={() => {
                renderDescription(ld.key);
              }}
              onMouseLeave={() => {
                renderDescription('');
              }}
            >
              <div className={classes.image}>
                <div className={classes.circleAvatar}>
                  <img
                    width='100%'
                    height='100%'
                    alt={ld.name}
                    src={ld.image}
                  />
                </div>
              </div>
              <div className={classes.titleItem}>
                <h4>{ld.name}</h4>
              </div>
            </div>
          </NavLink>
        </div>
      ));
    }

    return '';
  };
  const renderAdminCenter = () => {
    if (loadingMenu) {
      return <ProjectContentLoader />;
    }
    return (
      <div className={classes.main}>
        <div className={classes.listContent}>
          <div
            className={classes.listCard}
            style={{ gap: `30px calc( 200px / ${listDescription.length} )` }}
          >
            {renderCard(listDescription)}
          </div>
          <div className={classes.description}>
            <p>{description?.description}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <AuthContext.Consumer>
      {(value) => (
        <div className={classes.container}>{renderAdminCenter()}</div>
      )}
    </AuthContext.Consumer>
  );
};

export default ScreenLayout;
