/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import { actions } from '@root/redux';
import isBoolean from 'lodash/isBoolean';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACCESS_LEVEL } from '@root/utils';
import { getAccessLevelFilterOrDashboard } from './helpers';

const useSiteList = () => {
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const siteList = useSelector((appState) => appState.site.sites);
  const siteDetail = useSelector((appState) => appState.site.site);
  const siteListLoadingState = useSelector((state) => state.site.loadingMenu);
  const spacesFetchState = useSelector((state) => state.site?.spacesFetchState);
  const projectsFetchState = useSelector(
    (state) => state.site?.projectFetchState,
  );
  const fetchSiteDetailState = useSelector(
    (appState) => appState.site.fetchSiteDetailState,
  );
  const firstLicenseSite = siteList?.find(
    (i) => i.isJiraLicense || i.isConfluenceLicense,
  );
  const getSiteOptions = () =>
    siteList?.map((s) => ({
      ...s,
      label: s.name,
      value: s.siteId,
    })) || [];

  const getSites = async () => {
    dispatch(actions.getSiteList());
  };
  const getSiteDetailProject = (projects) => {
    if (Array.isArray(projects))
      dispatch(
        actions.getDetailItemDataForProject({
          projects: siteDetail?.projects,
          siteId: siteDetail?.siteId,
          atlassianUsers: siteDetail?.atlassianUsers,
        }),
      );
  };
  const updateSiteDetailProject = (items) => {
    dispatch(actions.updateDetailItemDataForProject(items));
  };

  const getSiteDetailData = async (site) => {
    if (site?.siteId) {
      dispatch(
        actions.getSiteDetailprojectsAndSpacesBySiteId({
          siteId: site.siteId,
        }),
      );
    }
  };

  const getDataSpace = () => {
    dispatch(actions.getDetailDataSpace());
  };
  const currentFilter = useMemo(
    () => ({
      siteId: query.get('siteId') || firstLicenseSite?.siteId || '',
      searchType: query.get('searchType'),
      isPublic:
        // eslint-disable-next-line no-nested-ternary
        query.get('isPublic') === 'true'
          ? true
          : query.get('isPublic') === 'false'
          ? false
          : '',
      keyword: query.get('keyword'),
    }),
    [siteList, query],
  );

  // lọc project
  const isFilteredProject = useCallback((item, filter) => {
    let filterByAccessLvl = true;
    let isFilterByKeyword = false;
    if (item.project) {
      if (filter.searchType === 'site') {
        isFilterByKeyword =
          !filter.keyword ||
          item.siteName?.toLowerCase() === filter.keyword.toLowerCase();
      } else if (filter.searchType === 'organization') {
        isFilterByKeyword = item.organizationName
          ?.toLowerCase()
          .includes(filter.keyword || '');
      } else {
        isFilterByKeyword = item.project?.name
          ?.toLowerCase()
          .includes(filter.keyword || '');
      }

      if (filter.accessLevel !== ACCESS_LEVEL.ALL) {
        filterByAccessLvl = item.accessLevel === filter.accessLevel;
      }
    }
    return filterByAccessLvl && isFilterByKeyword;
  }, []);

  // lọc space
  const isFilteredSpace = useCallback((item, filter) => {
    let filterByAccessLvl = true;
    let isFilterByKeyword = false;
    if (item) {
      if (filter.searchType === 'site') {
        isFilterByKeyword =
          !filter.keyword ||
          item.siteName?.toLowerCase() === filter.keyword.toLowerCase();
      } else if (filter.searchType === 'organization') {
        isFilterByKeyword = item.organizationName
          ?.toLowerCase()
          .includes(filter.keyword || '');
      } else {
        isFilterByKeyword = item?.name
          ?.toLowerCase()
          .includes(filter.keyword || '');
      }
      if (filter.accessLevel !== ACCESS_LEVEL.ALL) {
        filterByAccessLvl = item.accessLevel === filter.accessLevel;
      }
    }
    return filterByAccessLvl && isFilterByKeyword;
  }, []);

  // lọc warning
  const isFilteredWarning = useCallback((item, filter) => {
    let filterByAccessLvl = true;
    let isFilterByKeyword = false;
    if (item) {
      if (filter.searchType === 'site') {
        isFilterByKeyword =
          !filter.keyword ||
          item.siteName?.toLowerCase() === filter.keyword.toLowerCase();
      } else if (filter.searchType === 'organization') {
        isFilterByKeyword = item.organizationName
          ?.toLowerCase()
          .includes(filter.keyword || '');
      } else {
        isFilterByKeyword = true;
      }
      if (
        filter.accessLevel !== ACCESS_LEVEL.ALL &&
        filter.accessLevel !== ACCESS_LEVEL.OPEN
      ) {
        const isBoolean = item.isGlobalSharing ? 'public' : 'private';
        filterByAccessLvl = isBoolean === filter.accessLevel;
      }
    }
    return filterByAccessLvl && isFilterByKeyword;
  }, []);

  const handleFilterProject = (detail, filter) => {
    let result = { ...detail };

    if (isBoolean(filter?.isPublic) || filter?.keyword || filter?.searchType)
      result = {
        ...result,
        projects:
          result.projects?.filter((item) => isFilteredProject(item, filter)) ||
          [],
      };
    return result;
  };

  const sortAccessSpaces = (a, b) => b.accessLevel - a.accessLevel;

  const handleFilterSpace = (spaces, filter) => {
    const newSpaces =
      spaces?.filter((item) => isFilteredSpace(item, filter)) || [];
    return newSpaces;
  };

  const handleFilterProjectData = (dataSpace, filter) => {
    const newProjects =
      dataSpace?.filter((item) => isFilteredProject(item, filter)) || [];
    return newProjects;
  };

  const handleFilterWarningData = (dataWarning, filter) => {
    const newWarnings =
      dataWarning?.filter((item) => isFilteredWarning(item, filter)) || [];
    return newWarnings;
  };

  const handleFilterDashboardOrFilter = (filter, originalData) => {
    let newData = Array.isArray(originalData) ? [...originalData] : [];

    if (filter.searchType === 'site') {
      newData = !filter.keyword
        ? newData
        : newData.filter(
            (item) =>
              item.siteName?.toLowerCase() === filter.keyword.toLowerCase(),
          );
    } else if (filter.searchType === 'organization') {
      newData = newData.filter((item) =>
        item.organizationName?.toLowerCase().includes(filter.keyword || ''),
      );
    } else {
      newData = newData.filter((item) =>
        item.name?.toLowerCase().includes(filter.keyword || ''),
      );
    }
    if (filter.accessLevel && filter.accessLevel !== ACCESS_LEVEL.ALL) {
      newData = newData.filter(
        (item) =>
          filter.accessLevel === getAccessLevelFilterOrDashboard(item.viewers),
      );
    }
    return newData;
  };

  const updateGetDetailState = (state) => {
    dispatch(actions.updateStateSiteDetailFetchState(state));
  };

  const getSpaceItemDetailData = ({ pageIndex, pageSize }) => {
    dispatch(
      actions.getDetailItemDataForSpaces({
        pageIndex,
        pageSize,
        spaces: siteDetail?.spaces,
        siteId: siteDetail?.siteId,
        atlassianUsers: siteDetail?.atlassianUsers,
      }),
    );
  };

  const filteredSiteDetail = useMemo(
    () => handleFilterProject(siteDetail, currentFilter),
    [siteDetail, currentFilter],
  );

  return {
    data: {
      siteList,
      currentFilter,
      siteDetail,
      filteredSiteDetail,
      fetchSiteDetailState,
      siteListLoadingState,
      spacesFetchState,
      projectsFetchState,
    },
    actions: {
      getSiteOptions,
      getSiteDetailProject,
      updateSiteDetailProject,
      getSites,
      getSiteDetailData,
      updateGetDetailState,
      handleFilterDashboardOrFilter,
      getSpaceItemDetailData,
      getDataSpace,
      handleFilterSpace,
      handleFilterProjectData,
      handleFilterWarningData,
    },
  };
};
export default useSiteList;
