import { makeStyles } from '@material-ui/styles';
import React from 'react';
import OrganizationInfo from './OrganizationInfo';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const OrganizationTable = (props) => {
  const {
    isOpenDialog,
    setIsOpenDialog,
    isUpdateData,
    setIsUpdateData,
    changedOrg,
    orgEdit,
    setOrgEdit,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <OrganizationInfo
        setOrgEdit={setOrgEdit}
        orgEdit={orgEdit}
        changedOrg={changedOrg}
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        isUpdateData={isUpdateData}
        setIsUpdateData={setIsUpdateData}
      />
    </div>
  );
};

export default OrganizationTable;
