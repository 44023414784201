import React from 'react';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import { useTranslation } from 'react-i18next';
import SectionMessage from '@atlaskit/section-message';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  modalBody: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
  },
  successMessage: {
    color: 'green',
  },
});

export default function SetupDeviceNotificationModal({ onClose }) {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <ModalTransition>
      <Modal width='medium'>
        <ModalHeader>
          <ModalTitle>
            <div className={classes.modalTitle}>
              <span>{t('mfa.settings.success.title')}</span>
            </div>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SectionMessage appearance='success'>
            <div className={classes.modalBody}>
              <div className={classes.successMessage}>
                {t('mfa.settings.success.message')}
              </div>
              <div>{t('mfa.settings.success.note')}</div>
            </div>
          </SectionMessage>
        </ModalBody>
        <ModalFooter>
          <Button appearance='subtle' onClick={onClose}>
            {t('common.login')}
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
}
