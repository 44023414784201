import i18next from 'i18next';

const errorMessageAuth = (message) => {
  switch (message) {
    case 'Invalid email format':
      return i18next.t('auth.signup.invalidEmailFormat');
    case 'E-mail already in use':
      return i18next.t('auth.signup.emailExist');
    case 'Password must be at least 12 characters, and contains at least 1 number, 1 special character, 1 lowercase letter!':
      return i18next.t('auth.login.passwordFormatError');
    case 'Passwords do NOT match':
      return i18next.t('auth.signup.passwordNotMatch');
    default:
      return i18next.t('auth.login.serverError');
  }
};

export default errorMessageAuth;
