/* eslint-disable no-unused-vars */
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import IDatePicker from '@root/components/common/IDatePicker';
import Form, { Field } from '@atlaskit/form';
import SectionMessage from '@atlaskit/section-message';
import TextField from '@atlaskit/textfield';
import { makeStyles } from '@material-ui/styles';
import { actions } from '@root/redux';
import siteActions from '@root/redux/site/actions';
import { organizationAPIs, siteAPIs } from '@root/services';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_TYPE } from '@root/components/common/MessageSnackbar';
import i18n from '@root/translations/i18n';
import InfoIcon from '@atlaskit/icon/glyph/info';
import Popup from '@atlaskit/popup';
import ModalDialog, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { MODAL_KEY } from '@root/components/common/modal';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 24px 24px 40px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '50px',
    marginBottom: '2rem',
  },
  link: {
    textDecoration: 'none',
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  title: {
    color: 'rgb(25, 118, 210)',
    fontSize: '20px',
  },
  linkBack: {
    textDecoration: 'none',
  },
  form: {
    width: '50%',
    margin: 'auto',
  },
  organizationName: {
    color: '#6B778C',
    display: 'block',
  },
  btnTestApi: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginRight: 'auto',
  },
  btnSave: {
    display: 'flex',
    justifyContent: 'right',
  },
  message: {
    width: '50% !important',
    marginTop: '1rem !important',
  },
  '@media screen and (max-width: 900px)': {
    form: {
      width: '100%',
    },
  },
  // modalbody: {
  //   minHeight: '800px',
  //   maxHeight: '800px',
  // },
}));

const LINK_1_EN =
  'https://support.atlassian.com/organization-administration/docs/manage-an-organization-with-the-admin-apis/';

const LINK_1_JA =
  'https://support.atlassian.com/ja/organization-administration/docs/manage-an-organization-with-the-admin-apis/';

export const updateOrgId = async ({
  apiKey,
  organizationId,
  organizationName,
  dispatch,
}) => {
  const response = await siteAPIs.updateOrganizationId({
    apiKey,
    orgId: organizationId,
  });
  const updatedSites = response?.data?.sites;
  dispatch(
    siteActions.updateOrganizationIdForSiteListState({
      sites: updatedSites,
      orgId: organizationId,
      organizationName,
    }),
  );
};

const OrganizationEditModal = ({
  isOpen = false,
  organization,
  updateOrg = () => {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    orgApikeyId: organization?.id,
    organizationName: organization?.orgName,
    apiKey: organization?.apiKey,
    oid: organization?.atlasOrgId,
    expirationDate: organization?.expirationDate,
  });
  const [errorTestApi, setErrorTestApi] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [isValidExpirationDate, setIsValidExpirationDate] = useState(false);
  const [isTestApiKey, setIsTestApiKey] = useState(false);
  const [isOpenNote, setIsOpenNote] = React.useState(false);
  const currentLang = i18n.language;
  const currentDate = new Date();
  const minDate = currentDate.toISOString().split('T')[0];
  const { t } = useTranslation();
  const handleTestAPI = async () => {
    setErrorTestApi('');
    setShowErrorMessage(true);
    setIsTestApiKey(true);
    try {
      newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.test, {
        orgIds: formValues.oid,
      });

      const test = await organizationAPIs.validOrganizationApikey({
        apiKey: formValues.apiKey,
        oid: formValues.oid,
      });

      if (test.data.isValid) {
        setFormValues({
          ...formValues,
          organizationId: test.data.organizationId,
        });
        setErrorTestApi(t('auditLog.form.successTestApikey'));
        setIsSave(true);
      } else {
        setErrorTestApi(t('auditLog.form.wrongTestApikey'));
      }
      setIsTestApiKey(false);
    } catch (error) {
      console.log('err: ', error);
    }
  };

  const back = () => {
    dispatch(
      actions.toggleModal({
        key: MODAL_KEY.EDIT_ORG_API_KEY,
      }),
    );
  };

  const handleSubmit = async () => {
    if (isSave) {
      if (formValues.expirationDate.trim() === '') {
        setErrorTestApi('');
        setIsValidExpirationDate(true);
        return;
      }
      newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.update, {
        orgIds: formValues.oid,
      });
      try {
        const response = await organizationAPIs.updateOrganizationApikey({
          orgApikeyId: formValues.orgApikeyId,
          apiKey: formValues.apiKey,
          expirationDate: new Date(formValues.expirationDate),
        });
        if (response.data.success) {
          await updateOrgId({
            apiKey: formValues.apiKey,
            organizationId: formValues.oid,
            organizationName: formValues.organizationName,
            dispatch,
          });
          dispatch(
            actions.openSnackbar({
              open: true,
              type: ALERT_TYPE.success,
              messageKey: 'snackbar.organizationUpdated',
            }),
          );
          updateOrg();
          back();
        } else {
          console.log('error:', response.data.message);
          dispatch(
            actions.openSnackbar({
              open: true,
              type: ALERT_TYPE.error,
              messageKey: 'snackbar.errorApiKeyExist',
            }),
          );
        }
      } catch (error) {
        console.log('error', error);
        dispatch(
          actions.openSnackbar({
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.failedToUpdateOrganization',
          }),
        );
      }
      setErrorTestApi(t('auditLog.form.successUpdateApikey'));
      setShowErrorMessage(false);
    } else {
      setErrorTestApi(t('auditLog.form.warningSave'));
    }
  };

  const ref = React.useRef();
  const handleClickOutside = (e) => {
    if (!ref.current?.contains(e.target)) {
      setIsOpenNote(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <ModalTransition>
          <ModalDialog width='medium' onClose={back}>
            <Form onSubmit={handleSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <ModalHeader>
                    <ModalTitle>
                      {t('organization.updateOrganization')}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Field
                      name='organizationName'
                      label={t('auditLog.form.organizationName')}
                      defaultValue=''
                      className={classes.formField}
                      styles={{ display: 'flex' }}
                    >
                      {({ fieldProps }) => (
                        <span className={classes.organizationName}>
                          {formValues.organizationName || ''}
                        </span>
                      )}
                    </Field>
                    <Field
                      name='apiKey'
                      label={t('auditLog.form.apiKey')}
                      defaultValue=''
                      className={classes.formField}
                      styles={{ display: 'flex' }}
                    >
                      {({ fieldProps }) => (
                        <>
                          <Popup
                            zIndex={999}
                            isOpen={isOpenNote}
                            onClose={() => {
                              setIsOpenNote(false);
                            }}
                            placement='bottom-start'
                            content={() => (
                              <div
                                style={{
                                  maxWidth: '448px',
                                  padding: '16px 24px',
                                }}
                                ref={ref}
                              >
                                {t('organization.noteApiKey')}
                                <a
                                  className={classes.link}
                                  href={
                                    currentLang === 'en' ? LINK_1_EN : LINK_1_JA
                                  }
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {t('organization.noteApiKeyLink')}
                                </a>
                              </div>
                            )}
                            trigger={(triggerProps) => (
                              <span
                                {...triggerProps}
                                style={{
                                  // position: 'absolute',
                                  color: 'rgb(66, 82, 110)',
                                  display: 'inline-block',
                                }}
                                onClick={() => {
                                  setIsOpenNote(true);
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    position: 'relative',
                                    top: '4px',
                                  }}
                                >
                                  <InfoIcon size='medium' />
                                </div>
                              </span>
                            )}
                          />
                          <TextField
                            {...fieldProps}
                            className={classes.input}
                            value={formValues.apiKey}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                apiKey: e.target.value,
                              });
                              setIsSave(false);
                            }}
                          />
                        </>
                      )}
                    </Field>
                    <Field
                      name='expirationDate'
                      label={t('auditLog.form.expirationDate')}
                      defaultValue=''
                      className={classes.formField}
                      styles={{ display: 'flex' }}
                    >
                      {({ fieldProps }) => (
                        <>
                          <IDatePicker
                            {...fieldProps}
                            className={classes.input}
                            value={formValues.expirationDate}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                expirationDate: e,
                              });
                            }}
                            // eslint-disable-next-line no-undef
                            minDate={minDate}
                          />
                          <LoadingButton
                            appearance='primary'
                            onClick={() => handleTestAPI()}
                            className={classes.btnTestApi}
                            isLoading={isTestApiKey}
                          >
                            {t('auditLog.form.buttonTestAPIKey')}
                          </LoadingButton>
                        </>
                      )}
                    </Field>
                    {errorTestApi &&
                      showErrorMessage &&
                      (errorTestApi === t('auditLog.form.successTestApikey') ||
                        errorTestApi ===
                          t('auditLog.form.successUpdateApikey')) && (
                        <SectionMessage
                          appearance='success'
                          className={classes.message}
                          styles={{ width: '50%' }}
                        >
                          {errorTestApi}
                        </SectionMessage>
                      )}
                    {errorTestApi &&
                      showErrorMessage &&
                      errorTestApi === t('auditLog.form.wrongTestApikey') && (
                        <SectionMessage
                          appearance='error'
                          className={classes.message}
                        >
                          {errorTestApi}
                        </SectionMessage>
                      )}
                    {isValidExpirationDate && (
                      <SectionMessage appearance='error'>
                        {t('auditLog.form.invalidExpirationDate')}
                      </SectionMessage>
                    )}
                    {errorTestApi &&
                      errorTestApi === t('auditLog.form.warningSave') && (
                        <SectionMessage
                          appearance='warning'
                          className={classes.message}
                        >
                          {errorTestApi}
                        </SectionMessage>
                      )}
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        className={classes.btnBack}
                        onClick={() => {
                          back();
                        }}
                      >
                        {t('common.cancel')}
                      </Button>
                      <LoadingButton
                        type='submit'
                        appearance='primary'
                        className={classes.btnSave}
                        isLoading={submitting}
                      >
                        {t('common.update')}
                      </LoadingButton>
                    </ButtonGroup>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </ModalDialog>
        </ModalTransition>
      </div>
    </div>
  );
};

export default OrganizationEditModal;
