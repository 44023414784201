/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import auditLog from '../../images/auditlog.svg';
import security from '../../images/security.svg';
import productstatistic from '../../images/productstatistic.svg';
import useractivity from '../../images/useractivity.svg';
import userinventory from '../../images/userinventory.svg';
import ipAllowList from '../../images/ipaddress.png';
import ScreenLayout from '../common/ScreenLayout';

const AdminCenter = (props) => {
  const { siteAdmin } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const siteList = useSelector((state) => state?.site?.siteList);

  const listDescription = [
    {
      key: 'security',
      name: t('navBar.security'),
      image: security,
      description: t('adminCenterDescription.security'),
    },
    {
      key: 'audit-log',
      name: t('navBar.auditLogPage'),
      image: auditLog,
      description: t('adminCenterDescription.auditLog'),
    },
    {
      key: 'activity/product-data-statistic',
      name: t('navBar.activityProductStatistic'),
      image: productstatistic,
      description: t('adminCenterDescription.productDataStatistic'),
    },
    {
      key: 'activity/user-activity',
      name: t('navBar.userActivity'),
      image: useractivity,
      description: t('adminCenterDescription.userActivity'),
    },
    {
      key: 'activity/user-inventory',
      name: t('navBar.activityUserInventory'),
      image: userinventory,
      description: t('adminCenterDescription.userInventory'),
    },
    {
      key: 'ip-allowlist',
      name: t('navBar.ipAllowList'),
      image: ipAllowList,
      description: t('adminCenterDescription.ipAllowlist'),
    },
  ];
  const rootPath = '/admin-center';

  const keypage = 'admin';

  return (
    <ScreenLayout
      listDescription={listDescription}
      rootPath={rootPath}
      keypage={keypage}
      siteAdmin={siteAdmin}
    />
  );
};

export default AdminCenter;
