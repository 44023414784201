import ChevronUpCircleIcon from '@atlaskit/icon/glyph/chevron-up-circle';
import ChevronDownCircleIcon from '@atlaskit/icon/glyph/chevron-down-circle';
import VidFullScreenOffIcon from '@atlaskit/icon/glyph/vid-full-screen-off';
import VidFullScreenOnIcon from '@atlaskit/icon/glyph/vid-full-screen-on';
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem ',
    border: '0.5px solid #C1C7D0',
    borderRadius: 8,
    marginBottom: '2rem',
  },
  collapseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h4': {
      margin: 0,
    },
  },
}));

const CollapseSection = ({
  title,
  renderTitle,
  defaultOpen = false,
  onBarLineChart = false,
  children,
  id,
  renderCollapseAction,
  onViewLineChart,
  onViewFullScreen,
  className,
  hideExpandedIcon,
  onExitFullScreen = () => {},
}) => {
  const [expanded, setExpanded] = React.useState(defaultOpen || false);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [isShowLineChart, setIsShowLineChart] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleViewFullScreen = () => {
    setFullScreen(!fullScreen);
    if (onViewFullScreen) onViewFullScreen();
  };
  const handleExitFullScreen = () => {
    setFullScreen(!fullScreen);
    onExitFullScreen();
  };

  const handleViewLineChart = () => {
    setIsShowLineChart(!isShowLineChart);
    onViewLineChart();
  };
  return (
    <div className={`${classes.root} ${className || ''}`} id={id}>
      <div className={classes.collapseHeader}>
        {typeof renderTitle === 'function' ? (
          renderTitle()
        ) : (
          <h4 className={classes.title}>{title}</h4>
        )}

        <div className={classes.iconGroup}>
          {typeof renderCollapseAction === 'function' && renderCollapseAction()}{' '}
          {onViewFullScreen && (
            <Tooltip
              content={
                !fullScreen
                  ? t('activity.maximize')
                  : t('activity.singleColumn')
              }
            >
              {(tooltipProps) => (
                <>
                  {!fullScreen ? (
                    <IconButton
                      {...tooltipProps}
                      size='small'
                      onClick={handleViewFullScreen}
                    >
                      <VidFullScreenOnIcon size='medium' />
                    </IconButton>
                  ) : (
                    <IconButton
                      {...tooltipProps}
                      size='small'
                      onClick={handleExitFullScreen}
                    >
                      <VidFullScreenOffIcon size='medium' />
                    </IconButton>
                  )}
                </>
              )}
            </Tooltip>
          )}
          {onBarLineChart && expanded && (
            <Tooltip
              content={
                !isShowLineChart
                  ? t('activity.viewBarChart')
                  : t('activity.viewLineChart')
              }
            >
              {(tooltipProps) => (
                <IconButton
                  {...tooltipProps}
                  size='small'
                  onClick={handleViewLineChart}
                >
                  {isShowLineChart ? (
                    <GraphBarIcon size='medium' primaryColor='#6E6E6E' />
                  ) : (
                    <GraphLineIcon size='medium' primaryColor='#6E6E6E' />
                  )}
                </IconButton>
              )}
            </Tooltip>
          )}
          {!hideExpandedIcon && (
            <Tooltip
              content={expanded ? t('activity.minimize') : t('activity.expand')}
            >
              {(tooltipProps) => (
                <IconButton
                  {...tooltipProps}
                  size='small'
                  onClick={handleExpand}
                >
                  {expanded ? (
                    <ChevronUpCircleIcon size='medium' />
                  ) : (
                    <ChevronDownCircleIcon size='medium' />
                  )}
                </IconButton>
              )}
            </Tooltip>
          )}
        </div>
      </div>
      <Collapse in={expanded} timeout='auto'>
        {children}
      </Collapse>
    </div>
  );
};
export { CollapseSection };
