/* eslint-disable no-nested-ternary */
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import Lozenge from '@atlaskit/lozenge';
import Textfield from '@atlaskit/textfield';
import { makeStyles } from '@material-ui/styles';
import { organizationAPIs } from '@root/services';
import { checkDate } from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import appActions from '@root/redux/app/actions';
import OrganizationDeleteModal from './OrganizationDeleteModal';
import OrganizationEditModal from './OrganizationEditModal';
import OrganizationInforModal from './OrganizationInforModal';

const useStyles = makeStyles(() => ({
  container1: {
    maxHeight: '450px',
    minHeight: '450px',
    padding: '0 0px',
    marginBottom: '10px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    '& table': {},
    '& th': {
      backgroundColor: '#f2f2f2',
      color: '#000',
      '& >span:before': {
        right: '8px',
        bottom: '20px',
      },
      '& >span:after': {
        right: '8px',
        bottom: '12px',
      },

      '&:hover': {
        backgroundColor: '#f2f2f2 !important',
      },
      '& > button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '&:before': {
          right: '8px',
          bottom: '20px',
        },
        '&:after': {
          right: '8px',
          bottom: '12px',
        },
      },
    },
    '& th:first-child, & td:first-child': {
      paddingLeft: 8,
    },
  },
  main: {
    maxHeight: '450px',
    minHeight: '440px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& th': {
      '&:focus': {
        outline: 'solid 2px transparent',
      },
    },
    '& nav': {
      position: 'absolute',
      left: '45%',
      top: '86%',
      zIndex: 99,
    },
  },
  headerOrgfirstCol: {
    display: 'flex',
    width: '100%',
    fontSize: '14px',
    '& >span': {
      minHeight: '40px',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
  },
  headerOrg: {
    display: 'flex',
    width: '100%',
    borderLeft: '2px #e2e2e2 solid',
    fontSize: '14px',
    '& >span': {
      minHeight: '40px',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
  },
  headerOrgLastCol: {
    fontSize: '14px',
    borderLeft: '2px #e2e2e2 solid',
    '& >span': {
      minHeight: '40px',
      alignItems: 'center',
      display: 'flex',
    },
  },
  titleOrg: {
    fontSize: '14px',
    fontWeight: 500,
  },
  datevalid: {
    width: '80px',
    height: '20px',
    margin: 'auto',
    lineHeight: '20px',
    fontSize: '11px',
    fontWeight: 'bold',
    backgroundColor: 'Gainsboro',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
  dateinvalid: {
    width: '80px',
    height: '20px',
    margin: 'auto',
    lineHeight: '20px',
    fontSize: '11px',
    fontWeight: 'bold',
    backgroundColor: 'Gainsboro',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    color: 'red',
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    '& > div:nth-child(1)': {
      marginRight: '15px',
    },
    '& > div:nth-child(2)': {
      marginRight: '5px',
    },
  },
  exprire: {
    justifyContent: 'center',
  },
  status: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
  action: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
  container: {
    maxHeight: '450px',
    minHeight: '450px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  addOrganization: {
    fontSize: '14px',
    textDecoration: 'none',
    marginTop: '10px',
  },
  editBtn: {
    fontSize: '14px !important',
    textDecoration: 'none',
  },
  deleteBtn: {
    fontSize: '14px !important',
    textDecoration: 'none',
  },
  title: {
    color: '#091E42',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    fontWeight: 500,
    marginRight: 8,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  search: {
    display: 'flex',
    '& > div:nth-child(1)': {
      marginRight: '10px',
      marginTop: '10px',
    },
    '& > div:nth-child(2)': {
      marginRight: '10px',
      marginTop: '10px',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

export const DisplayMode = {
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  DELETE_ORGANIZATION: 'DELETE_ORGANIZATION',
  LIST_ORGANIZATION: 'LIST_ORGANIZATION',
  SETTING_ORGANIZATION: 'SETTING_ORGANIZATION',
};

const OrganizationInfo = (props) => {
  const {
    isOpenDialog,
    setIsOpenDialog,
    isUpdateData,
    setIsUpdateData,
    changedOrg,
    orgEdit,
    setOrgEdit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayMode, setDisplayMode] = useState(DisplayMode.LIST_ORGANIZATION);
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [organization, setOrganizations] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [orgSearch, setOrgSearch] = useState('');
  const [orgApikeyId, setOrgApikeyId] = useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [listorg, setListOrg] = useState([]);
  const [loadingOrg, setLoadingOrg] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSetDisplayMode = (mode) => {
    setDisplayMode(mode);
  };

  const handleSelectOrganization = (obj) => {
    setSelectedOrganization(obj);
  };

  const fetchOrganizations = async () => {
    const res = await organizationAPIs.getAllOrganizationAPIKeys();
    const orgs = res.data.organizations;
    const orgsInfo = [];
    for (let i = 0; i < orgs.length; i += 1) {
      if (checkDate(orgs[i].expirationDate)) {
        if (orgs[i].apikeyError === 'wrong_key') {
          orgsInfo.push({
            organization: orgs[i],
            name: orgs[i].organizationName,
            date: orgs[i].expirationDate,
            registeredBy: orgs[i].registeredBy,
            registeredOn: orgs[i].registeredOn,
            status: false,
            check: true,
          });
        } else {
          orgsInfo.push({
            organization: orgs[i],
            name: orgs[i].organizationName,
            date: orgs[i].expirationDate,
            registeredBy: orgs[i].registeredBy,
            registeredOn: orgs[i].registeredOn,
            status: true,
            check: true,
          });
        }
      }
      if (!checkDate(orgs[i].expirationDate)) {
        if (orgs[i].apikeyError === 'wrong_key') {
          orgsInfo.push({
            organization: orgs[i],
            name: orgs[i].organizationName,
            date: orgs[i].expirationDate,
            status: false,
            check: false,
            registeredBy: orgs[i].registeredBy,
            registeredOn: orgs[i].registeredOn,
          });
        } else {
          orgsInfo.push({
            organization: orgs[i],
            name: orgs[i].organizationName,
            date: orgs[i].expirationDate,
            status: false,
            check: false,
            registeredBy: orgs[i].registeredBy,
            registeredOn: orgs[i].registeredOn,
          });
        }
      }
    }
    setListOrg(orgsInfo);
    const rows = orgsInfo.map((item, index) => ({
      cells: [
        {
          key: 'No.',
          content: <span className={classes.titleOrg}>{index + 1}</span>,
        },
        {
          key: item.date,
          content: (
            <div>
              {item.check ? (
                <span className={classes.datevalid}>
                  {moment(item.date).format(t('common.dateFormat'))}
                </span>
              ) : (
                <span className={classes.dateinvalid}>
                  {' '}
                  {moment(item.date).format(t('common.dateFormat'))}
                </span>
              )}
            </div>
          ),
        },
        {
          key: item.status,
          content: (
            <span className={classes.status}>
              {item.status ? (
                <Lozenge appearance='inprogress'>
                  {t('organization.valid')}
                </Lozenge>
              ) : (
                <Lozenge appearance='moved'>
                  {t('organization.invalid')}
                </Lozenge>
              )}
            </span>
          ),
        },
        {
          key: item.registeredBy,
          content: (
            <span className={classes.titleOrg}>{item.registeredBy}</span>
          ),
        },
        {
          key: item.registeredOn,
          content: (
            <span className={classes.titleOrg}>{item.registeredOn}</span>
          ),
        },
        {
          key: item.organization.organizationId,
          content: (
            <span className={classes.btn}>
              <Button
                className={classes.editBtn}
                appearance='link'
                onClick={() => {
                  newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.edit, {
                    orgIds: item.organization.organizationId,
                  });
                  setOrgApikeyId(item.organization.orgApikeyId);
                  handleSetDisplayMode('SETTING_ORGANIZATION');
                  handleSelectOrganization(item.organization);
                }}
              >
                {t('common.edit')}
              </Button>
              <Button
                className={classes.deleteBtn}
                appearance='link'
                onClick={() => {
                  handleSetDisplayMode('DELETE_ORGANIZATION');
                  handleSelectOrganization(item.organization);
                }}
              >
                {t('common.delete')}
              </Button>
            </span>
          ),
        },
      ],
    }));
    setOrganizations(rows);
    setLoadingOrg(false);
    if (orgSearch) {
      setTableData(
        rows.filter((input) =>
          input.cells[0].key.toLowerCase().includes(orgSearch),
        ),
      );
    } else setTableData(rows);
  };

  useEffect(() => {
    newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.open);
  }, []);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (orgApikeyId) {
      const selectorg = listorg.find(
        (item) => item?.organization?.orgApikeyId === orgApikeyId,
      );
      if (selectorg) {
        setSelectedOrganization(selectorg.organization);
        setDisplayMode('SETTING_ORGANIZATION');
      }
    }
  }, [orgApikeyId, listorg]);

  useEffect(() => {
    setCurrentPage(1);
  }, [orgSearch]);

  React.useEffect(() => {
    if (orgEdit) {
      setOrgApikeyId(orgEdit);
    }
  }, [orgEdit, listorg]);

  const submit = () => {
    changedOrg();
    fetchOrganizations();
    // Used for reloading org api key list. Hide org api key notice in homepage DASAFE-2588
    dispatch(appActions.renewModalData({ key: 'organization-api-key' }));
    if (window.location.pathname === '/admin-center/audit-log')
      dispatch(appActions.renewModalData({ key: 'organization-audit-log' }));
    if (window.location.pathname === '/admin-center/security')
      dispatch(appActions.renewModalData({ key: 'security' }));
    if (window.location.pathname === '/admin-center/installed-status')
      dispatch(appActions.renewModalData({ key: 'installed-status' }));
  };

  const head = {
    cells: [
      {
        key: 'No.',
        content: (
          <div className={classes.headerOrgfirstCol}>
            <span>{t('organization.numero')}</span>
          </div>
        ),
        width: 5,
      },
      {
        key: 'API Key Expire',
        content: (
          <div className={classes.headerOrg}>
            <span className={classes.exprire}>
              {t('organization.apiKeyExpire')}
            </span>
          </div>
        ),
        isSortable: true,
        width: 15,
      },
      {
        key: 'API Key Status',
        content: (
          <div className={classes.headerOrg}>
            <span className={classes.status}>
              {t('organization.apiKeyStatus')}
            </span>
          </div>
        ),
        width: 15,
      },
      {
        key: 'Registered by',
        content: (
          <div className={classes.headerOrg}>
            <span className={classes.exprire}>
              {t('organization.registeredBy')}
            </span>
          </div>
        ),
        isSortable: true,
        width: 25,
      },
      {
        key: 'Registered on',
        content: (
          <div className={classes.headerOrg}>
            <span className={classes.exprire}>
              {t('organization.registeredOn')}
            </span>
          </div>
        ),
        isSortable: true,
        width: 25,
      },
      {
        key: 'Actions',
        content: (
          <div className={classes.headerOrgLastCol}>
            <span className={classes.action}>{t('organization.actions')}</span>
          </div>
        ),
        width: 25,
      },
    ],
  };

  const tableEmpty = (
    <div
      style={{
        marginBottom: '30%',
        color: '#45526C',
        fontWeight: 'normal',
        fontSize: '20px',
      }}
    >
      {t('activity.emptyDataTable')}
    </div>
  );

  const handleSearchOrganization = (e) => {
    const { value } = e.target;
    setOrgSearch(value);
    if (value && value.trim().length > 0) {
      const filterData = organization.filter((input) =>
        input.cells[0].key.toLowerCase().includes(value.toLowerCase().trim()),
      );
      setTableData(filterData);
    } else setTableData(organization);
  };

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <span className={classes.titleWrapper}>
          <span className={classes.title}>
            {' '}
            {t('organization.organizationSettings')}
          </span>
        </span>
      </div>
      <div className={classes.container1}>
        <div className={classes.main}>
          <DynamicTable
            head={head}
            emptyView={tableEmpty}
            rows={tableData}
            rowsPerPage={10}
            defaultPage={1}
            isFixedSize
            isLoading={loadingOrg}
            defaultSortKey='name'
            defaultSortOrder='ASC'
            page={currentPage}
            onSetPage={(page) => setCurrentPage(page)}
          />
        </div>
        {((openDialog === true &&
          displayMode === DisplayMode.ADD_ORGANIZATION) ||
          isOpenDialog === true) && (
          <OrganizationInforModal
            setOpenDialog={setOpenDialog}
            back={() => {
              setIsOpenDialog(false);
              setDisplayMode(DisplayMode.LIST_ORGANIZATION);
            }}
            isUpdateData={isUpdateData}
            setIsUpdateData={setIsUpdateData}
            updateOrg={() => submit()}
          />
        )}
        {displayMode === DisplayMode.SETTING_ORGANIZATION &&
          selectedOrganization.orgApikeyId === orgApikeyId && (
            <OrganizationEditModal
              organization={selectedOrganization}
              back={() => {
                setDisplayMode(DisplayMode.LIST_ORGANIZATION);
                setOrgApikeyId('');
                if (orgEdit) setOrgEdit('');
              }}
              isUpdateData={isUpdateData}
              setIsUpdateData={setIsUpdateData}
              updateOrg={() => submit()}
            />
          )}
        {displayMode === DisplayMode.DELETE_ORGANIZATION && (
          <OrganizationDeleteModal
            organization={selectedOrganization}
            back={() => {
              setDisplayMode(DisplayMode.LIST_ORGANIZATION);
            }}
            isUpdateData={isUpdateData}
            setIsUpdateData={setIsUpdateData}
            updateOrg={() => submit()}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationInfo;
