import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';

const PaginationTotalNumber = ({
  currentPage,
  totalPage,
  rowsPerPage,
  actualItem,
  translateKey = 'activity.totalUsersText',
}) => {
  // #region state

  const [page, setPage] = React.useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);
  // #endregion useEffect
  return (
    <>
      <Typography color='textSecondary'>
        <Trans
          i18nKey={translateKey}
          values={{
            start: ((page - 1) * rowsPerPage + 1).toLocaleString('gb-en'),
            end:
              actualItem < rowsPerPage * page
                ? parseInt(actualItem, 10).toLocaleString('gb-en')
                : parseInt(rowsPerPage * page, 10).toLocaleString('gb-en'),
            total: parseInt(totalPage, 10).toLocaleString('gb-en'),
          }}
        />
      </Typography>
    </>
  );
};
export default PaginationTotalNumber;
