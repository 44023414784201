import store from '@root/store';

const addPageAction = (actionName, properties) => {
  const currentUser = store.getState()?.app?.currentUser;
  const domain = currentUser?.email?.split('@')[1];

  if (window && window.newrelic) {
    window.newrelic.addPageAction(actionName, {
      userId: currentUser?.id,
      userEmail: currentUser?.email,
      domain,
      ...(properties || {}),
    });
  }
};

export default {
  addPageAction,
};
