import WarningIcon from '@atlaskit/icon/glyph/warning';
import Spinner from '@atlaskit/spinner';
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PAGE_ACTION } from '@root/utils/pageAction';
import newRelicUtils from '@root/utils/newRelicUtils';
import logo from '../../images/d_accel_yoko.png';
import { AuthContext } from './AuthProvider';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '332px',
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '55px',
  },
  logo: {
    width: '192px',
  },
  title: {
    color: '#000',
    fontSize: '20px',
    fontWeight: '600',
    marginTop: '17px',
  },
  instruction: {
    margin: '48px 0',
    fontSize: '14px',
    color: '#a49cb5',
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '14px',
  },
  inputLabel: {
    color: '#6D6E6F',
    fontSize: '14px',
    fontWeight: '800',
  },
  input: {
    '& > input': {
      marginTop: '12px',
      color: '#000',
      border: '1px solid #C1C7D0',
      height: '40px',
      fontSize: '14px',
      background: ' #F5F5F5',
      boxSizing: 'border-box',
      fontWeight: 500,
      borderRadius: '40px',
      padding: '0 10px 1px 18px',
      '&:focus': {
        border: '1px solid #FAD100',
        background: '#F5F5F5 !important',
      },
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
    '& > select': {
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
          boxShadow: '0 0 0 2px #fad10042 !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
  },
  button: {
    marginTop: '16px',
    cursor: 'pointer',
    background: 'linear-gradient(92.7deg, #EC6423 -20.42%, #FAD100 114.43%)',
    borderRadius: '40px',
    border: 'none',
    fontWeight: 700,
    fontSize: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '48px',
    alignItems: 'center',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.85',
      color: '#FFFFFF',
    },
    color: '#F0FFF0',
  },
  errorMessageContainer: {
    color: '#D92929',
    fontSize: '16px',
    width: '100%',
    marginTop: '10px',
    display: 'flex',
  },
  errorMessage: {
    marginLeft: '5px',
  },
}));

const VerifyMfa = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sendMFACode } = useContext(AuthContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [totpCode, setTotpCode] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState(null);

  async function verifyMfa(event) {
    event.preventDefault();
    setIsLoading(true);
    newRelicUtils.addPageAction(PAGE_ACTION.login.verifyMFA, {
      userEmail: user.username,
      userId: null,
    });
    await sendMFACode(user, totpCode)
      .then((data) => {})
      .catch((error) => {
        console.log('error', error?.code, error?.message);
        switch (error.code) {
          case 'CodeMismatchException':
            setErrorMessage(t('mfa.verify.invalidCode'));
            break;
          case 'ExpiredCodeException':
            setErrorMessage(t('mfa.verify.ExpiredCodeException'));
            break;
          default:
            setErrorMessage(t('mfa.verify.serverError'));
            break;
        }
      });
    setIsLoading(false);
  }

  return (
    <form className={classes.form} onSubmit={verifyMfa}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt='Daccel' />
      </div>

      <span className={classes.title}>{t('mfa.verify.title')}</span>

      <span className={classes.instruction}>{t('mfa.verify.instruction')}</span>

      <div className={classes.inputField}>
        <span className={classes.inputLabel}>{t('mfa.verify.codeLabel')}</span>
        <Input
          required
          disabled={isLoading}
          className={classes.input}
          disableUnderline
          placeholder={t('mfa.verify.codePlaceholder')}
          value={totpCode}
          onChange={(event) => setTotpCode(event.target.value)}
          onBlur={() => setTotpCode(totpCode.trim())}
        />
      </div>

      {errorMessage && (
        <div className={classes.errorMessageContainer}>
          <WarningIcon />
          <span className={classes.errorMessage}>{errorMessage}</span>
        </div>
      )}

      <button
        type='submit'
        className={classes.button}
        disabled={isLoading}
        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
      >
        {isLoading ? <Spinner /> : t('mfa.verify.loginButton')}
      </button>
    </form>
  );
};

export default VerifyMfa;
