import React from 'react';
import { styled } from '@material-ui/styles';
import WarningIcon from '@atlaskit/icon/glyph/warning';

const Message = styled('div')({
  color: '#6D6E6F',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  margin: '5px 0px',
  fontSize: '14px',
});

/**
 * `MessageWarning` is a React functional component that displays a warning message
 * with an icon. It accepts the following props:
 *
 * @param {string} text - The warning message text to display.
 * @param {string} [color='#E5686F'] - The color of the warning icon. Defaults to a red tone.
 * @param {string} [size='small'] - There are three icon sizes – small (16px), medium (24px), and large (32px). Defaults to small.
 *
 * @returns {JSX.Element} The `MessageWarning` component.
 */
const WarningMessage = ({ text, color = '#E5686F', size = 'small' }) => (
  <Message>
    <WarningIcon primaryColor={color} size={size} />
    {text}
  </Message>
);

export { WarningMessage };
