/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Select from '@atlaskit/select';
import OrgSiteInformation from '@root/components/Activity/OrgSiteInformation';
import Textfield from '@atlaskit/textfield';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Toggle from '@atlaskit/toggle';
import { LoadingSpinner } from '@root/components/common/icons';
import { NOTIFICATION_ACCESS_LEVEL, NOTIFICATION_FEATURE } from '@root/utils';
import { securityAPIs, siteNotificationApis } from '@root/services';
import confluenceAPIs from '@root/services/confluenceApis';
import {
  changeTimeToServerTime,
  changeTimeToUserTime,
  timePicker,
} from '@root/components/Notification/common';
import { actions } from '@root/redux';
import { ALERT_TYPE } from '@root/components/common/MessageSnackbar';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import ButtonCustom from './ButtonCustom';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 'calc(100% - 80px)',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0px',
    padding: '0px 0px',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  timepickerStyle: {
    width: '150px',
    backgroundColor: 'white !important',
    border: '2px solid rgba(0,0,0,0.12)',
    borderRadius: '5px',
  },
  contentStyle: {
    width: '200px',
  },
  containerStyle: {
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    paddingBottom: '20px',
    paddingTop: '10px',
  },
  elementStyle: {
    paddingBottom: '20px',
  },
  selectContainer: {
    paddingTop: '10px',
    width: '400px',
  },
  isSelectedButton: {
    backgroundColor: '#D1E5FF !important',
    border: '2px solid #D1E5FF !important',
    borderRadius: '5px !important',
    '& span': {
      color: '#0052CC !important',
      lineHeight: '2',
    },
  },
  defaultButton: {
    backgroundColor: '#fff !important',
    boxSizing: 'border-box',
    border: '2px solid rgba(0,0,0,0.12) !important',
    borderRadius: '5px !important',
    '& span': {
      lineHeight: '2',
    },
  },
  selectOption: {
    width: 'auto',
    backgroundColor: '#fff !important',
    border: '2px solid rgba(0,0,0,0.12) !important',
    borderRadius: '5px !important',
  },
  notificationStatus: {
    width: '200px',
  },
  daccelMessage: {
    position: 'relative',
    color: '#E5686F',
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px 8px',
    fontSize: '14px',
  },
}));

const INITIAL_SETTING = {
  status: 'disabled',
  jiraSystem: false,
  jiraProject: {
    public: false,
    open: false,
    warning: false,
    excludedProjectId: [],
  },
  jiraFilter: {
    public: false,
    open: false,
    excludedFilterId: [],
  },
  jiraDashboard: {
    public: false,
    open: false,
    excludedDashboardId: [],
  },
  confluenceSpace: {
    public: false,
    open: false,
    excludedSpaceId: [],
  },
  // changeOfProducts: false,
  // changeOfPlugins: false,
  notificationEmail: '',
  notificationTime: null,
};

const NotificationEditModal = ({
  isOpen,
  closeModal,
  editingSite,
  fetchAllNotificationSettings,
}) => {
  const { siteId, siteName, organizationName, organizationId } = editingSite;
  const classes = useStyles();
  const loadingMenu = useSelector((state) => state.site.loadingMenu);
  const dispatch = useDispatch();
  const [notificationSetting, setNotificationSetting] = useState();
  const [notificationSettingTempo, setNotificationSettingTempo] = useState();
  const [siteProject, setSiteProject] = useState([]);
  const [siteFilter, setSiteFilter] = useState([]);
  const [siteDashboard, setSiteDashboard] = useState([]);
  const [siteSpace, setSiteSpace] = useState([]);
  const [option, setOption] = useState();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isValidTime, setIsValidTime] = useState(true);
  const [isValidFeature, setIsValidFeature] = useState(true);
  const [loadingSetting, setLoadingSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  // const NotificationStatus = [
  //   { label: t('notification.enable'), value: true, extra: 'extra' },
  //   { label: t('notification.disable'), value: false },
  // ]

  const formatEnabledFeatures = (siteSetting) => {
    if (!siteSetting) {
      return {
        ...INITIAL_SETTING,
        siteId,
        siteName,
        organizationName,
        organizationId,
      };
    }
    const {
      status,
      enabledFeatures: features,
      excludedProject,
      excludedDashboard,
      excludedFilter,
      excludedSpace,
      notificationEmail,
      notificationTime,
    } = siteSetting;
    const setting = JSON.parse(JSON.stringify(INITIAL_SETTING)); // deep clone
    const jiraSystem = features.includes(NOTIFICATION_FEATURE.JIRA_SYSTEM);
    if (jiraSystem) {
      setting.jiraSystem = true;
    }

    const jiraProject = features.filter((feature) =>
      feature.includes(NOTIFICATION_FEATURE.JIRA_PROJECT),
    );
    if (jiraProject.length > 0) {
      const types = jiraProject.map((feature) => feature.split('-')[1]);
      setting.jiraProject.public = types.includes(
        NOTIFICATION_ACCESS_LEVEL.PUBLIC,
      );
      setting.jiraProject.open = types.includes(NOTIFICATION_ACCESS_LEVEL.OPEN);
      setting.jiraProject.warning = types.includes(
        NOTIFICATION_ACCESS_LEVEL.WARNING,
      );
      setting.jiraProject.excludedProjectId = excludedProject.map((project) =>
        project.project_id.toString(),
      );
    }

    const jiraFilter = features.filter((feature) =>
      feature.includes(NOTIFICATION_FEATURE.JIRA_FILTER),
    );
    if (jiraFilter.length > 0) {
      const types = jiraFilter.map((feature) => feature.split('-')[1]);
      setting.jiraFilter.public = types.includes(
        NOTIFICATION_ACCESS_LEVEL.PUBLIC,
      );
      setting.jiraFilter.open = types.includes(NOTIFICATION_ACCESS_LEVEL.OPEN);
      setting.jiraFilter.excludedFilterId = excludedFilter.map((filter) =>
        filter.filter_id.toString(),
      );
    }

    const jiraDashboard = features.filter((feature) =>
      feature.includes(NOTIFICATION_FEATURE.JIRA_DASHBOARD),
    );
    if (jiraDashboard.length > 0) {
      const types = jiraDashboard.map((feature) => feature.split('-')[1]);
      setting.jiraDashboard.public = types.includes(
        NOTIFICATION_ACCESS_LEVEL.PUBLIC,
      );
      setting.jiraDashboard.open = types.includes(
        NOTIFICATION_ACCESS_LEVEL.OPEN,
      );
      setting.jiraDashboard.excludedDashboardId = excludedDashboard.map(
        (dashboard) => dashboard.dashboard_id.toString(),
      );
    }

    const confluenceSpace = features.filter((feature) =>
      feature.includes(NOTIFICATION_FEATURE.CONFLUENCE_SPACE),
    );
    if (confluenceSpace.length > 0) {
      const types = confluenceSpace.map((feature) => feature.split('-')[1]);
      setting.confluenceSpace.public = types.includes(
        NOTIFICATION_ACCESS_LEVEL.PUBLIC,
      );
      setting.confluenceSpace.open = types.includes(
        NOTIFICATION_ACCESS_LEVEL.OPEN,
      );
      setting.confluenceSpace.excludedSpaceId = excludedSpace.map((space) =>
        space.space_id.toString(),
      );
    }

    // setting.changeOfProducts = features.includes(NOTIFICATION_FEATURE.PRODUCT_CHANGE)
    // setting.changeOfPlugins = features.includes(NOTIFICATION_FEATURE.PLUGIN_CHANGE)
    setting.notificationEmail = notificationEmail;
    setting.notificationTime = changeTimeToUserTime(notificationTime);
    setting.status = status;
    // set state
    return {
      ...setting,
      siteId,
      siteName,
      organizationName,
      organizationId,
    };
  };

  const handleButtonClick = (feature, accessLevel) => {
    switch (feature) {
      case NOTIFICATION_FEATURE.JIRA_SYSTEM:
        setNotificationSetting((prev) => ({
          ...prev,
          jiraSystem: !prev.jiraSystem,
        }));
        break;
      case NOTIFICATION_FEATURE.JIRA_PROJECT:
        setNotificationSetting((prev) => ({
          ...prev,
          jiraProject: {
            ...prev.jiraProject,
            [accessLevel]: !prev.jiraProject[accessLevel],
          },
        }));
        break;
      case NOTIFICATION_FEATURE.JIRA_FILTER:
        setNotificationSetting((prev) => ({
          ...prev,
          jiraFilter: {
            ...prev.jiraFilter,
            [accessLevel]: !prev.jiraFilter[accessLevel],
          },
        }));
        break;
      case NOTIFICATION_FEATURE.JIRA_DASHBOARD:
        setNotificationSetting((prev) => ({
          ...prev,
          jiraDashboard: {
            ...prev.jiraDashboard,
            [accessLevel]: !prev.jiraDashboard[accessLevel],
          },
        }));
        break;
      case NOTIFICATION_FEATURE.CONFLUENCE_SPACE:
        setNotificationSetting((prev) => ({
          ...prev,
          confluenceSpace: {
            ...prev.confluenceSpace,
            [accessLevel]: !prev.confluenceSpace[accessLevel],
          },
        }));
        break;
      default:
        break;
    }
  };

  const createOptionSelect = () => {
    const options = {
      jiraProject: [],
      jiraFilter: [],
      jiraDashboard: [],
      confluenceSpace: [],
    };
    if (siteProject.length > 0) {
      siteProject.forEach((project) => {
        options.jiraProject.push({
          label: project.project.name,
          value: project.project.project_id,
        });
      });
    }

    if (siteFilter.length > 0) {
      siteFilter.forEach((filter) => {
        options.jiraFilter.push({
          label: filter.name,
          value: filter.filterId,
        });
      });
    }

    if (siteDashboard.length > 0) {
      siteDashboard.forEach((dashboard) => {
        options.jiraDashboard.push({
          label: dashboard.name,
          value: dashboard.dashboardId,
        });
      });
    }

    if (siteSpace.length > 0) {
      siteSpace.forEach((space) => {
        option.confluenceSpace = [];
        options.confluenceSpace.push({
          label: space.name,
          value: space.spaceId,
        });
      });
    }
    setOption(options);
  };

  const checkEmptyFeature = (setting) => {
    const {
      jiraSystem,
      jiraProject,
      jiraFilter,
      jiraDashboard,
      confluenceSpace,
    } = setting;
    const disabledJiraProject =
      !jiraProject.public && !jiraProject.open && !jiraProject.warning;
    const disabledJiraFilter = !jiraFilter.public && !jiraFilter.open;
    const disabledJiraDashboard = !jiraDashboard.public && !jiraDashboard.open;
    const disabledConfluenceSpace =
      !confluenceSpace.public && !confluenceSpace.open;
    return (
      !jiraSystem &&
      disabledJiraProject &&
      disabledJiraFilter &&
      disabledJiraDashboard &&
      disabledConfluenceSpace
    );
  };

  const handleUpdateSetting = async (setting) => {
    setIsLoading(true);
    const {
      jiraSystem,
      jiraProject,
      jiraFilter,
      jiraDashboard,
      confluenceSpace,
      // changeOfProducts,
      // changeOfPlugins,
      notificationEmail,
      notificationTime,
      status,
    } = setting;
    // Check empty feature
    const emptyFeature = checkEmptyFeature(setting);
    if (emptyFeature) {
      setIsValidFeature(false);
    }
    // Check email and time
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!notificationEmail) {
      setIsValidEmail(false);
    } else if (!regex.test(String(notificationEmail).toLowerCase())) {
      setIsValidateEmail(false);
    }
    if (notificationTime === null) {
      setIsValidTime(false);
    }
    const correctEmail =
      notificationEmail && regex.test(String(notificationEmail).toLowerCase());
    if (correctEmail && notificationTime && !emptyFeature) {
      const enabledFeatures = [];
      if (jiraSystem) {
        enabledFeatures.push(NOTIFICATION_FEATURE.JIRA_SYSTEM);
      }
      if (jiraProject.public) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_PROJECT}-${NOTIFICATION_ACCESS_LEVEL.PUBLIC}`,
        );
      }
      if (jiraProject.open) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_PROJECT}-${NOTIFICATION_ACCESS_LEVEL.OPEN}`,
        );
      }
      if (jiraProject.warning) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_PROJECT}-${NOTIFICATION_ACCESS_LEVEL.WARNING}`,
        );
      }
      if (jiraFilter.public) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_FILTER}-${NOTIFICATION_ACCESS_LEVEL.PUBLIC}`,
        );
      }
      if (jiraFilter.open) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_FILTER}-${NOTIFICATION_ACCESS_LEVEL.OPEN}`,
        );
      }
      if (jiraDashboard.public) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_DASHBOARD}-${NOTIFICATION_ACCESS_LEVEL.PUBLIC}`,
        );
      }
      if (jiraDashboard.open) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.JIRA_DASHBOARD}-${NOTIFICATION_ACCESS_LEVEL.OPEN}`,
        );
      }
      if (confluenceSpace.public) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.CONFLUENCE_SPACE}-${NOTIFICATION_ACCESS_LEVEL.PUBLIC}`,
        );
      }
      if (confluenceSpace.open) {
        enabledFeatures.push(
          `${NOTIFICATION_FEATURE.CONFLUENCE_SPACE}-${NOTIFICATION_ACCESS_LEVEL.OPEN}`,
        );
      }

      // if (changeOfProducts) {
      //   enabledFeatures.push(NOTIFICATION_FEATURE.PRODUCT_CHANGE)
      // }

      // if (changeOfPlugins) {
      //   enabledFeatures.push(NOTIFICATION_FEATURE.PLUGIN_CHANGE)
      // }

      const newSetting = {
        status,
        enabledFeatures,
        excludedProjectId: jiraProject.excludedProjectId,
        excludedDashboardId: jiraDashboard.excludedDashboardId,
        excludedFilterId: jiraFilter.excludedFilterId,
        excludedSpaceId: confluenceSpace.excludedSpaceId,
        notificationEmail,
        notificationTime: changeTimeToServerTime(notificationTime),
      };

      const res = await siteNotificationApis.saveSiteNotificationSetting({
        siteId,
        setting: newSetting,
      });
      if (res.status === 200) {
        dispatch(
          actions.openSnackbar({
            open: true,
            type: ALERT_TYPE.success,
            messageKey: 'snackbar.saveNotificationSuccess',
          }),
        );
      } else {
        dispatch(
          actions.openSnackbar({
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.saveNotificationFailed',
          }),
        );
      }
      newRelicUtils.addPageAction(PAGE_ACTION.notification.save, {
        siteIds: editingSite.siteId,
      });
      setIsLoading(false);
      closeModal();
      fetchAllNotificationSettings();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (editingSite) {
        newRelicUtils.addPageAction(PAGE_ACTION.notification.edit, {
          siteIds: editingSite.siteId,
        });
        setLoadingSetting(true);
        // Get current setting
        const response =
          await siteNotificationApis.getDetailSiteNotificationSetting({
            siteId: editingSite.siteId,
          });
        if (response.status === 200) {
          const siteSetting = response.data.data;
          const setting = formatEnabledFeatures(siteSetting);
          setNotificationSetting(setting);
          setNotificationSettingTempo(setting);
        }
        // Get all projects, filter, dashboards, spaces
        const siteProjectResponse = await securityAPIs.getProjectsInformation({
          siteIds: editingSite.siteId,
        });
        if (siteProjectResponse.status === 200) {
          const filteredProjects =
            siteProjectResponse?.data?.data.length > 0
              ? siteProjectResponse.data.data.filter(
                  (project) =>
                    project.accessLevel === 'open' ||
                    project.accessLevel === 'public' ||
                    project.project.is_vulnerability === true,
                )
              : [];
          setSiteProject(filteredProjects);
        }

        const siteFilterResponse = await securityAPIs.getFilterInformation({
          siteIds: editingSite.siteId,
        });
        if (siteFilterResponse.status === 200) {
          const filteredFilters =
            siteFilterResponse?.data?.data.length > 0
              ? siteFilterResponse.data.data.filter(
                  (filter) =>
                    filter.accessLevel === 'open' ||
                    filter.accessLevel === 'public',
                )
              : [];
          setSiteFilter(filteredFilters);
        }

        const siteDashboardResponse =
          await securityAPIs.getDashBoardInformation({
            siteIds: editingSite.siteId,
          });
        if (siteDashboardResponse.status === 200) {
          const filteredDashboards =
            siteDashboardResponse?.data?.data.length > 0
              ? siteDashboardResponse.data.data.filter(
                  (dashboard) =>
                    dashboard.accessLevel === 'open' ||
                    dashboard.accessLevel === 'public',
                )
              : [];
          setSiteDashboard(filteredDashboards);
        }

        const siteSpaceResponse = await confluenceAPIs.getSpacesInformation({
          siteIds: editingSite.siteId,
        });
        if (siteSpaceResponse.status === 200) {
          const filteredSpaces =
            siteSpaceResponse?.data?.data.length > 0
              ? siteSpaceResponse.data.data.filter(
                  (space) =>
                    space.accessLevel === 'open' ||
                    space.accessLevel === 'public',
                )
              : [];
          setSiteSpace(filteredSpaces);
        }
        setLoadingSetting(false);
      }
    })();
  }, [editingSite]);

  useEffect(() => {
    createOptionSelect();
  }, [siteProject, siteFilter, siteDashboard, siteSpace]);

  useEffect(() => {
    if (notificationSetting) {
      const emptyFeature = checkEmptyFeature(notificationSetting);
      if (!emptyFeature) {
        setIsValidFeature(true);
      }
    }
  }, [JSON.stringify(notificationSetting)]);

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal} width={750} shouldFitContainer>
            <ModalHeader>
              <ModalTitle>
                <h1>{t('notification.editNotification')}</h1>
                <OrgSiteInformation
                  organizationName={organizationName}
                  siteNameList={[siteName]}
                />
                {!isValidFeature && (
                  <div className={classes.daccelMessage}>
                    <WarningIcon primaryColor='#E5686F' />
                    {t('notification.requiredFeature')}
                  </div>
                )}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              {loadingMenu ||
              !notificationSetting ||
              loadingSetting ||
              !option ? (
                <LoadingSpinner />
              ) : (
                <div
                  style={{
                    height:
                      notificationSetting.status === 'enabled'
                        ? '70vh'
                        : '15vh',
                  }}
                >
                  <div
                    className={classes.containerStyle}
                    style={{
                      borderBottom:
                        notificationSetting.status === 'enabled'
                          ? '1px solid rgba(0,0,0,0.12)'
                          : '0px',
                    }}
                  >
                    <div className={classes.centerAlign}>
                      <span className={classes.contentStyle}>
                        <b>{t('notification.toggleStatus')}</b>
                      </span>
                      <div>
                        <Toggle
                          size='large'
                          isChecked={notificationSetting.status === 'enabled'}
                          onChange={() => {
                            setNotificationSetting((prev) => ({
                              ...prev,
                              status:
                                prev.status === 'enabled'
                                  ? 'disabled'
                                  : 'enabled',
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {notificationSetting.status === 'enabled' && (
                    <>
                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <span className={classes.contentStyle}>
                            <b>{t('notification.notificationTime')}</b>
                            <span style={{ color: '#AE2E24 ' }}> *</span>
                          </span>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <div className={classes.timepickerStyle}>
                              <Select
                                placeholder={t('notification.chooseTime')}
                                options={timePicker}
                                defaultValue={timePicker.find(
                                  (option) =>
                                    option.value ===
                                    notificationSetting.notificationTime,
                                )}
                                isClearable={false}
                                onChange={(value) => {
                                  setIsValidTime(true);
                                  setNotificationSetting((prev) => ({
                                    ...prev,
                                    notificationTime: value.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {!isValidTime && (
                          <div className={classes.centerAlign}>
                            <span className={classes.contentStyle}> </span>
                            <div className={classes.daccelMessage}>
                              <WarningIcon primaryColor='#E5686F' />
                              {t('notification.requiredTime')}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <div className={classes.contentStyle}>
                            <b>{t('notification.jiraSystem')}</b>
                          </div>
                          <div>
                            <Button
                              className={
                                notificationSetting.jiraSystem
                                  ? classes.isSelectedButton
                                  : classes.defaultButton
                              }
                              onClick={() =>
                                handleButtonClick(
                                  NOTIFICATION_FEATURE.JIRA_SYSTEM,
                                )
                              }
                            >
                              {t('security.public')}
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <div className={classes.contentStyle}>
                            <b>{t('notification.jiraProject')}</b>
                          </div>

                          <div>
                            <ButtonGroup>
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraProject[
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_PROJECT,
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC,
                                  );
                                }}
                                content={t('security.public')}
                              />
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraProject[
                                    NOTIFICATION_ACCESS_LEVEL.OPEN
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_PROJECT,
                                    NOTIFICATION_ACCESS_LEVEL.OPEN,
                                  );
                                }}
                                content={t('security.open')}
                              />
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraProject[
                                    NOTIFICATION_ACCESS_LEVEL.WARNING
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_PROJECT,
                                    NOTIFICATION_ACCESS_LEVEL.WARNING,
                                  );
                                }}
                                content={t('security.warning')}
                              />
                            </ButtonGroup>
                            <div className={classes.selectContainer}>
                              <div className={classes.selectOption}>
                                <Select
                                  options={option.jiraProject}
                                  isMulti
                                  isSearchable
                                  placeholder={t('notification.chooseProject')}
                                  defaultValue={option.jiraProject.filter(
                                    (option) =>
                                      notificationSetting.jiraProject.excludedProjectId.includes(
                                        option.value.toString(),
                                      ),
                                  )}
                                  onChange={(value) =>
                                    setNotificationSetting((prev) => ({
                                      ...prev,
                                      jiraProject: {
                                        ...prev.jiraProject,
                                        excludedProjectId: value.map((v) =>
                                          v.value.toString(),
                                        ),
                                      },
                                    }))
                                  }
                                  closeMenuOnSelect={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <div className={classes.contentStyle}>
                            <b>{t('notification.jiraDashboard')}</b>
                          </div>

                          <div>
                            <ButtonGroup>
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraDashboard[
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_DASHBOARD,
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC,
                                  );
                                }}
                                content={t('security.public')}
                              />
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraDashboard[
                                    NOTIFICATION_ACCESS_LEVEL.OPEN
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_DASHBOARD,
                                    NOTIFICATION_ACCESS_LEVEL.OPEN,
                                  );
                                }}
                                content={t('security.open')}
                              />
                            </ButtonGroup>
                            <div className={classes.selectContainer}>
                              <div className={classes.selectOption}>
                                <Select
                                  options={option.jiraDashboard}
                                  isMulti
                                  isSearchable
                                  placeholder={t(
                                    'notification.chooseDashboard',
                                  )}
                                  defaultValue={option.jiraDashboard.filter(
                                    (option) =>
                                      notificationSetting.jiraDashboard.excludedDashboardId.includes(
                                        option.value.toString(),
                                      ),
                                  )}
                                  onChange={(value) =>
                                    setNotificationSetting((prev) => ({
                                      ...prev,
                                      jiraDashboard: {
                                        ...prev.jiraDashboard,
                                        excludedDashboardId: value.map((v) =>
                                          v.value.toString(),
                                        ),
                                      },
                                    }))
                                  }
                                  closeMenuOnSelect={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <div className={classes.contentStyle}>
                            <b>{t('notification.jiraFilter')}</b>
                          </div>

                          <div>
                            <ButtonGroup>
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraFilter[
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_FILTER,
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC,
                                  );
                                }}
                                content={t('security.public')}
                              />
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.jiraFilter[
                                    NOTIFICATION_ACCESS_LEVEL.OPEN
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.JIRA_FILTER,
                                    NOTIFICATION_ACCESS_LEVEL.OPEN,
                                  );
                                }}
                                content={t('security.open')}
                              />
                            </ButtonGroup>
                            <div className={classes.selectContainer}>
                              <div className={classes.selectOption}>
                                <Select
                                  options={option.jiraFilter}
                                  isMulti
                                  isSearchable
                                  placeholder={t('notification.chooseFilter')}
                                  defaultValue={option.jiraFilter.filter(
                                    (option) =>
                                      notificationSetting.jiraFilter.excludedFilterId.includes(
                                        option.value.toString(),
                                      ),
                                  )}
                                  onChange={(value) =>
                                    setNotificationSetting((prev) => ({
                                      ...prev,
                                      jiraFilter: {
                                        ...prev.jiraFilter,
                                        excludedFilterId: value.map((v) =>
                                          v.value.toString(),
                                        ),
                                      },
                                    }))
                                  }
                                  closeMenuOnSelect={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <div className={classes.contentStyle}>
                            <b>{t('notification.confluenceSpace')}</b>
                          </div>

                          <div>
                            <ButtonGroup>
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.confluenceSpace[
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.CONFLUENCE_SPACE,
                                    NOTIFICATION_ACCESS_LEVEL.PUBLIC,
                                  );
                                }}
                                content={t('security.public')}
                              />
                              <ButtonCustom
                                isSelected={
                                  notificationSetting.confluenceSpace[
                                    NOTIFICATION_ACCESS_LEVEL.OPEN
                                  ]
                                }
                                handleButtonClick={() => {
                                  handleButtonClick(
                                    NOTIFICATION_FEATURE.CONFLUENCE_SPACE,
                                    NOTIFICATION_ACCESS_LEVEL.OPEN,
                                  );
                                }}
                                content={t('security.open')}
                              />
                            </ButtonGroup>
                            <div className={classes.selectContainer}>
                              <div className={classes.selectOption}>
                                <Select
                                  options={option.confluenceSpace}
                                  isMulti
                                  isSearchable
                                  placeholder={t('notification.chooseSpace')}
                                  defaultValue={option.confluenceSpace.filter(
                                    (option) =>
                                      notificationSetting.confluenceSpace.excludedSpaceId.includes(
                                        option.value.toString(),
                                      ),
                                  )}
                                  onChange={(value) =>
                                    setNotificationSetting((prev) => ({
                                      ...prev,
                                      confluenceSpace: {
                                        ...prev.confluenceSpace,
                                        excludedSpaceId: value.map((v) =>
                                          v.value.toString(),
                                        ),
                                      },
                                    }))
                                  }
                                  closeMenuOnSelect={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className={classes.containerStyle}>
                            <div className={classes.box}>
                              <div className={classes.contentStyle}>
                                <b>{t('notification.changeOfProducts')}</b>
                              </div>

                              <div className={classes.notificationStatus}>
                                <div className={classes.selectOption}>
                                  <Select
                                    options={NotificationStatus}
                                    defaultValue={NotificationStatus.find((option) => option.value === notificationSetting.changeOfProducts)}
                                    isSearchable
                                    onChange={(value) => setNotificationSetting(prev => ({
                                      ...prev,
                                      changeOfProducts: value.value,
                                    }))}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

                      {/* <div className={classes.containerStyle}>
                            <div className={classes.box}>
                              <div className={classes.contentStyle}>
                                <b>{t('notification.changeOfPlugins')}</b>
                              </div>

                              <div className={classes.notificationStatus}>
                                <div className={classes.selectOption}>
                                  <Select
                                    options={NotificationStatus}
                                    isSearchable
                                    defaultValue={NotificationStatus.find((option) => option.value === notificationSetting.changeOfPlugins)}
                                    onChange={(value) => setNotificationSetting(prev => ({
                                      ...prev,
                                      changeOfPlugins: value.value,
                                    }))}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

                      <div className={classes.containerStyle}>
                        <div className={classes.centerAlign}>
                          <span className={classes.contentStyle}>
                            <b>{t('notification.notificationEmail')}</b>
                            <span style={{ color: '#AE2E24 ' }}> *</span>
                          </span>
                          <div className={classes.selectContainer}>
                            <div className={classes.selectOption}>
                              <Textfield
                                appearance='none'
                                placeholder={t('notification.enterEmail')}
                                value={notificationSetting.notificationEmail}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setIsValidEmail(true);
                                  setIsValidateEmail(true);
                                  setNotificationSetting((prev) => ({
                                    ...prev,
                                    notificationEmail: value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {!isValidEmail && (
                          <div className={classes.centerAlign}>
                            <span className={classes.contentStyle}> </span>
                            <div className={classes.daccelMessage}>
                              <WarningIcon primaryColor='#E5686F' />
                              {t('notification.requiredEmail')}
                            </div>
                          </div>
                        )}
                        {!isValidateEmail && (
                          <div className={classes.centerAlign}>
                            <span className={classes.contentStyle}> </span>
                            <div className={classes.daccelMessage}>
                              <WarningIcon primaryColor='#E5686F' />
                              {t('notification.checkEmail')}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                appearance='subtle'
                autoFocus={false}
                onClick={closeModal}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                appearance='primary'
                isLoading={isLoading}
                isDisabled={
                  JSON.stringify(notificationSetting) ===
                  JSON.stringify(notificationSettingTempo)
                }
                onClick={() => {
                  handleUpdateSetting(notificationSetting);
                }}
              >
                {t('common.update')}
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default NotificationEditModal;
