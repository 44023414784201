/* eslint-disable no-param-reassign */
import moment from 'moment';
import userAPIs from '@root/services/userApis';
import { CIDR_REGEX } from './regexUtils';

export const uniqueArray = (array) =>
  array.filter((v, i, a) => a.indexOf(v) === i);
export const uniqueArrayObject = (array, key) =>
  Array.from(new Set(array.map((a) => a[key]))).map((n) =>
    array.find((a) => a[key] === n),
  );

export const getToken = () =>
  new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    AP.context.getToken((token) => {
      resolve(token);
    });
  });

export const noop = () => {};
export const isNotEmptyArray = (array) =>
  Array.isArray(array) && array.length > 0;
export const toQueryString = (obj) =>
  Object.entries(obj).reduce((acc, [key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((e) => {
        acc += `${(acc ? '&' : '') + key}=${e}`;
      });
    } else if (val !== null && val !== '' && val !== undefined) {
      acc += `${(acc ? '&' : '') + key}=${val}`;
    }
    return acc;
  }, '');

export const removeDuplicate = (arr) =>
  arr?.filter((value, index, self) => self && self.indexOf(value) === index);

export const isValidCIDR = (cird) =>
  !(!CIDR_REGEX.ip_v4.test(cird) && !CIDR_REGEX.ip_v6.test(cird));

export function checkDate(date) {
  const numberOfDays = moment(date).diff(moment(), 'days');
  return numberOfDays >= 0;
}

export const updateLastLoginDate = async (date) => {
  // update last login date
  const lastLoginDate = new Date(date).toISOString();
  try {
    await userAPIs.updateLastLoginDate({ lastLoginDate });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error when update last login date', error);
  }
};
