import { makeStyles } from '@material-ui/styles';

const commonStyles = makeStyles(() => ({
  headerAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0 30px',
  },
  jiraheader: {
    display: 'flex',
    minHeight: 40,
    padding: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '2px #e2e2e2 solid',
  },
  tableResponsive: {
    overflowX: 'auto',
    flex: 1,
    '& table': {
      minWidth: 800,
      // tableLayout: 'fixed',
      fontSize: 14,
    },
    '& th': {
      fontSize: '14px',
      background: '#F2F2F2',
      color: '#000',
      fontWeight: 'bold',
      boxSizing: 'border-box',
      minWidth: 200,
      '&:focus': {
        outline: 'solid 2px transparent',
      },
    },
    '& th:last-child > span > div': {
      borderRight: 'none !important',
    },
    '& th, & td': {
      padding: '4px 10px',
      verticalAlign: 'middle',
    },
    '& td': {
      padding: '10px',
      borderBottom: '1px solid #E2E2E2 !important',
    },
    '& th:first-child, & td:first-child': {
      paddingLeft: 10,
      minWidth: 160,
      wordBreak: 'break-word',
      maxWidth: 200,
    },
    '& th:last-child, & td:last-child': {
      maxWidth: 80,
      minWidth: 40,
      paddingRight: 10,
    },
    '& th:nth-child(3), & td:nth-child(3)': {
      maxWidth: 180,
      minWidth: 150,
    },
    '& th:nth-child(5), & td:nth-child(5)': {
      maxWidth: 200,
      minWidth: 150,
    },
    '& td:nth-child(3) > div > label': {
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
    '& td:last-child > div': {
      padding: '4px',
    },
    '& th >span::before, & th >span::after': {
      display: 'none',
    },
    '& nav': {
      display: 'none',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    minWidth: '60%',
    '& button': {
      border: '1px solid var(--ds-text,#0052CC)',
      backgroundColor: '#fff',
      fontSize: '14px',
      lineHeight: '28px',
      '&:hover': {
        backgroundColor:
          'var(--ds-background-neutral-pressed,rgba(179,212,255,0.6))',
        color: 'var(--ds-text,#0052CC) !important',
        borderColor: 'transparent',
      },
    },
  },
  marginRightButton: {
    marginRight: '8px',
  },
  row: {
    fontSize: '14px',
  },
  fixedHeightColumn: {
    maxHeight: 80,
    overflow: 'auto',
    verticalAlign: 'middle',
  },
  siteItem: {
    display: 'flex',
    height: '40px',
    boxSizing: 'border-box',
  },
  labelAddress: {
    backgroundColor: 'rgb(223, 225, 230)',
    padding: '2px 6px',
    width: 'fit-content',
    display: 'inline-block',
    margin: '4px',
    lineHeight: '18px',
    borderRadius: '5px',
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0',
  },
  searchMulti: {
    width: '100%',
  },
  searchSites: {
    width: '100%',
    minWidth: 260,
    fontSize: '14px',
    '& > div > div:nth-child(3)': {
      minWidth: '400px',
    },
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
  editBtn: {
    fontSize: '14px !important',
    textDecoration: 'none',
  },
  searchTitle: {
    marginRight: '20px',
    minWidth: 'fit-content',
  },
  searchItem: {
    minWidth: 230,
    '& > div': {
      height: '40px',
    },
    fontSize: '14px',
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
  options: {
    minHeight: 32,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#1E1F21',
    '& .organization-name': {
      color: '#6d6d6d',
      fontWeight: 'normal',
      marginLeft: 3,
    },
  },
  message: {
    color: '#6D6E6F',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    margin: '5px 0px',
    fontSize: '14px',
  },
  logo: {
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
    backgroundColor: 'rgb(244, 245, 247)',
  },
  productSite: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
  },
  product: {
    fontSize: '14px',
  },
  site: {
    fontSize: '12px',
    color: '#6b778c',
  },
  headerOrgLastCol: {
    fontSize: '14px',
    '& >span': {
      minHeight: '40px',
      alignItems: 'center',
      display: 'flex',
    },
  },
  action: {
    display: 'flex',
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    padding: '5px 0',
  },
  rowsPerPageOpt: {
    minWidth: 130,
    fontSize: '14px',
    '&:before': {
      display: 'none !important',
    },
    '& > div': {
      zIndex: 2,
    },
  },
  rightAction: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
  },
  searchTypeItem: {
    marginLeft: '8px',
    minWidth: 120,
    fontSize: '14px',
    '& div': {
      overflow: 'visible !important',
    },
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
  paginationStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  disabledCell: {
    opacity: 0.6,
  },
}));

export default commonStyles;
