import { siteAPIs } from '@root/services';
import React from 'react';

export const fetchCurrentUsers = async (siteIds) => {
  const ids = siteIds.filter((id) => id).join(',');
  const [{ data: siteUsersData }] = await Promise.all([
    siteAPIs.getSitesUsers(ids),
  ]);
  return {
    siteUsers: siteUsersData.siteUsers,
  };
};
export const UserManagerDefault = {
  siteUsers: [],
};

const UserManagerContext = React.createContext(UserManagerDefault);

export default UserManagerContext;
