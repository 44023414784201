import React from 'react';
import PageContainer from '../layout/PageContainer';
import UsersBoard from './UsersBoard';

const UserManagement = ({ isRefresh, setIsRefresh }) => (
  <PageContainer>
    <UsersBoard isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
  </PageContainer>
);

export default UserManagement;
