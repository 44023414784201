import Spinner from '@atlaskit/spinner';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import OrganizationTable from './Modal/OrganizationTable';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 'calc(100% - 80px)',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0px',
    padding: '0px 0px',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const Organization = (props) => {
  const {
    isOpenDialog,
    setIsOpenDialog,
    isUpdateData,
    setIsUpdateData,
    changedOrg,
    orgEdit,
    setOrgEdit,
  } = props;
  const classes = useStyles();
  const loadingMenu = useSelector((state) => state.site.loadingMenu);

  if (loadingMenu) {
    return (
      <div className={classes.loading}>
        <Spinner size='medium' />
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <OrganizationTable
        changedOrg={changedOrg}
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
        orgEdit={orgEdit}
        setOrgEdit={setOrgEdit}
        isUpdateData={isUpdateData}
        setIsUpdateData={setIsUpdateData}
      />
    </div>
  );
};

export default Organization;
