/* eslint-disable no-unused-vars */
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  errorMessage: {
    display: 'flex',
    border: 'none',
    fontSize: '14px',
    color: '#42526E',
    padding: '5px 0 5px',
    width: '100%',
  },
}));
/**
 *
 * @param {'error' | 'success' | 'warning' | 'info'} props.severity
 */
const InlineMessage = ({ variant, severity, title, children, ...props }) => {
  const classes = useStyles();
  return (
    <Alert
      variant='outlined'
      severity={severity || 'warning'}
      className={classes.errorMessage}
      {...props}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};
export { InlineMessage };
