import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Spinner from '@atlaskit/spinner';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
const LoadingSpinner = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <Spinner size='medium' />
      <h3>{t('common.loading')}</h3>
    </div>
  );
};
export { LoadingSpinner };
