import actions from '@root/redux/app/actions';
import { useDispatch } from 'react-redux';
import { ALERT_TYPE } from '@root/components/common/MessageSnackbar';
import i18next from 'i18next';

const useSnackbar = () => {
  const dispatch = useDispatch();

  const openSnack = (messageKey, type) =>
    dispatch(
      actions.openSnackbar({
        open: true,
        type,
        messageKey,
      }),
    );

  const openSuccessSnackbar = (messageKey) =>
    openSnack(messageKey, ALERT_TYPE.success);

  const openErrorSnackbar = (
    messageKey = i18next.t('snackbar.somethingWentWrong'),
  ) => openSnack(messageKey, ALERT_TYPE.error);

  return {
    openSnack,
    openErrorSnackbar,
    openSuccessSnackbar,
  };
};

export default useSnackbar;
