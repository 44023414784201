import Toggle from '@atlaskit/toggle';
import React, { useEffect } from 'react';

const MfaToggle = ({
  checked,
  loadingUsers,
  isDisabled,
  onClickToggleMFARequire,
}) => {
  const [isChecked, setIsChecked] = React.useState(checked);
  const [apiLoading, setApiLoading] = React.useState(false);
  const handleOnChangeMfaRequiredSite = async () => {
    if (!isChecked) {
      try {
        setIsChecked(true);
        setApiLoading(true);
        await onClickToggleMFARequire(true);
        setApiLoading(false);
      } catch (error) {
        setIsChecked(false);
      }
    } else {
      // disable MFA and call API to turn off MFA
      onClickToggleMFARequire(false);
    }
  };

  useEffect(() => {
    // update isChecked when calling API to turn off MFA
    if (!loadingUsers) {
      setIsChecked(checked);
    }
  }, [checked, loadingUsers]);

  return (
    <Toggle
      isChecked={isChecked}
      isDisabled={isDisabled || apiLoading}
      onChange={handleOnChangeMfaRequiredSite}
    />
  );
};

export default MfaToggle;
