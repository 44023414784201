import { backEndApi } from '@root/libs';

const api = '/api/v1';
const sitePath = '/install';
export default {
  autoInstallApp: (body) => backEndApi.post(`${api}${sitePath}`, body),
  updateInitialSetup: (body) => backEndApi.put(`${api}${sitePath}`, body),
  testAccessInstallApp: (body) =>
    backEndApi.post(`${api}${sitePath}/test-access`, body),
  getInstallationStatusByOrgIds: (orgIds) =>
    backEndApi.get(`${api}${sitePath}/status?orgIds=${orgIds.join(',')}`),
  applyPromotionCode: (body) =>
    backEndApi.post(`${api}${sitePath}/promotion`, body),
};
