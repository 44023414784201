import { backEndApi } from '@root/libs';

const api = '/api/v1';
const sitePath = '/auth';
const authAPIs = {
  signup: (body) => backEndApi.post(`${api}${sitePath}/signup`, body),
  signupConfirm: (body) => backEndApi.post(`${api}${sitePath}/verify`, body),
  resendOtpEmail: (body) =>
    backEndApi.post(`${api}${sitePath}/resend-otp`, body),
};
export default authAPIs;
