import { backEndApi } from '@root/libs';

const api = '/api/v1';
const sitePath = '/confluence';
const confluenceAPIs = {
  getSpacesInformation: ({ siteIds }) =>
    backEndApi.get(`${api}${sitePath}/spaces?siteIds=${siteIds}`),
  getSpace: (key) => backEndApi.get(`${api}${sitePath}/space/${key}`),
  renewSpacesData: (body) =>
    backEndApi.post(`${api}${sitePath}/space/renew`, body),
};
export default confluenceAPIs;
