/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useTranslation } from 'react-i18next';
import i18n from '@root/translations/i18n';
import moment from 'moment';

const IDatePicker = (props) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(props?.defaultValue || '');
  return (
    <DatePicker
      value={selectedDate}
      parseInputValue={(value, dateFormat) => {
        const date = value ? moment(value, dateFormat) : value;
        return date?.isValid() ? date.toDate() : '';
      }}
      placeholder={t('common.dateFormat')}
      dateFormat={t('common.dateFormat')}
      locale={i18n.language}
      {...props}
      onChange={(date) => {
        const maxDate = props?.maxDate;
        const minDate = props?.minDate;
        if (
          (maxDate &&
            date &&
            moment(date).isAfter(moment(maxDate, 'YYYY-MM-DD'), 'day')) ||
          (minDate &&
            date &&
            moment(date).isBefore(moment(minDate, 'YYYY-MM-DD'), 'day'))
        ) {
          setSelectedDate('');
          props?.onChange('');
        } else {
          setSelectedDate(date);
          props?.onChange(date);
        }
      }}
    />
  );
};

export default IDatePicker;
