/* eslint-disable no-unused-vars */
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import IDatePicker from '@root/components/common/IDatePicker';
import SectionMessage from '@atlaskit/section-message';
import i18n from '@root/translations/i18n';
import React, { useState } from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { organizationAPIs } from '@root/services';
import Form, { Field } from '@atlaskit/form';
import Popup from '@atlaskit/popup';
import InfoIcon from '@atlaskit/icon/glyph/info';
import TextField from '@atlaskit/textfield';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { actions } from '@root/redux';
import { MODAL_KEY } from '@root/components/common/modal';
import { ALERT_TYPE } from '@root/components/common/MessageSnackbar';
import { updateOrgId } from './OrganizationEditModal';

const LINK_1_EN =
  'https://support.atlassian.com/organization-administration/docs/manage-an-organization-with-the-admin-apis/';
const LINK_1_JA =
  'https://support.atlassian.com/ja/organization-administration/docs/manage-an-organization-with-the-admin-apis/';
const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 24px 24px 40px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '50px',
    marginBottom: '2rem',
  },
  title: {
    color: 'rgb(25, 118, 210)',
  },
  linkBack: {
    textDecoration: 'none',
  },
  form: {
    width: '50%',
    margin: 'auto',
  },
  organizationName: {
    color: 'rgb(25, 118, 210)',
    fontWeight: '600',
    display: 'block',
    borderBottom: '1px solid #eaeaea',
    padding: '10px 0 10px 5px',
  },
  link: {
    textDecoration: 'none',
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  btnTestApi: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginRight: 'auto',
  },
  message: {
    marginTop: '1rem',
  },
  '@media screen and (max-width: 900px)': {
    form: {
      width: '100%',
    },
  },
}));

const OrganizationInforModal = ({ isOpen, updateOrg }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    organizationName: '',
    apiKey: '',
    expirationDate: '',
  });
  const currentDate = new Date();
  const minDate = currentDate.toISOString().split('T')[0];

  const [errorTestApi, setErrorTestApi] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [isTestApiKey, setIsTestApiKey] = useState(false);
  const [isValidExpirationDate, setIsValidExpirationDate] = useState(false);

  const [isOpenNote, setIsOpenNote] = React.useState(false);
  const currentLang = i18n.language;

  const handleTestAPI = async () => {
    setErrorTestApi('');
    setShowErrorMessage(true);
    setIsTestApiKey(true);
    const test = await organizationAPIs.validOrganizationApikey({
      apiKey: formValues.apiKey,
    });
    setFormValues({
      ...formValues,
      organizationId: test.data.organizationId,
      organizationName: test.data.organizationName,
    });
    newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.test, {
      orgIds: test?.data?.organizationId,
    });

    if (test.data.isValid) {
      setErrorTestApi(t('auditLog.form.successTestApikey'));
      setIsSave(true);
    } else {
      setErrorTestApi(t('auditLog.form.wrongTestApikey'));
      setIsSave(false);
    }
    setIsTestApiKey(false);
  };

  const back = () => {
    dispatch(
      actions.toggleModal({
        key: MODAL_KEY.ADD_ORG_API_KEY,
      }),
    );
  };

  const handleSubmit = async () => {
    let apiKeyExist = false;
    if (isSave) {
      if (formValues.expirationDate.trim() === '') {
        setErrorTestApi('');
        setIsValidExpirationDate(true);
        return;
      }
      try {
        const res = await organizationAPIs.checkExistApikey({
          apiKey: formValues.apiKey,
        });
        apiKeyExist = res.data.exist;
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await organizationAPIs.createOrganizationApikey({
          apiKey: formValues.apiKey,
          expirationDate: new Date(formValues.expirationDate),
        });

        if (response.data.success) {
          await updateOrgId({
            apiKey: formValues.apiKey,
            organizationId: formValues.organizationId,
            organizationName: formValues.organizationName,
            dispatch,
          });
          dispatch(
            actions.openSnackbar({
              open: true,
              type: ALERT_TYPE.success,
              messageKey: 'snackbar.organizationCreated',
            }),
          );
          updateOrg();
          back();
        } else {
          console.log('error', response.data.message);
          dispatch(
            actions.openSnackbar({
              open: true,
              type: ALERT_TYPE.error,
              messageKey: 'snackbar.errorApiKeyExist',
            }),
          );
        }
      } catch (error) {
        console.log('error', error);
        dispatch(
          actions.openSnackbar({
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.failedToCreateOrganization',
          }),
        );
      }
      setErrorTestApi(t('auditLog.form.successCreateApikey'));
      setFormValues({
        organizationId: '',
        organizationName: '',
        apiKey: '',
        expirationDate: '',
      });
      setShowErrorMessage(false);
    } else {
      setErrorTestApi(t('auditLog.form.warningSave'));
    }
    if (!apiKeyExist) {
      newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.save, {
        orgIds: formValues.organizationId,
      });
    }
  };

  const ref = React.useRef();
  const handleClickOutside = (e) => {
    if (!ref.current?.contains(e.target)) {
      setIsOpenNote(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <ModalTransition>
          <Modal width='medium' onClose={back}>
            <Form onSubmit={handleSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <ModalHeader>
                    <ModalTitle>
                      {t('organization.createOrganization')}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Field
                      name='organizationName'
                      label={t('auditLog.form.organizationName')}
                      defaultValue=''
                    >
                      {({ fieldProps }) => (
                        <span className={classes.organizationName}>
                          {formValues.organizationName || ''}
                        </span>
                      )}
                    </Field>
                    <Field
                      style={{ position: 'relative' }}
                      name='apiKey'
                      label={t('auditLog.form.apiKey')}
                      defaultValue=''
                    >
                      {({ fieldProps }) => (
                        <>
                          <Popup
                            zIndex={999}
                            isOpen={isOpenNote}
                            onClose={() => {
                              setIsOpenNote(false);
                            }}
                            placement='bottom-start'
                            content={() => (
                              <div
                                style={{
                                  maxWidth: '448px',
                                  padding: '16px 24px',
                                }}
                                ref={ref}
                              >
                                {t('organization.noteApiKey')}
                                <a
                                  className={classes.link}
                                  href={
                                    currentLang === 'en' ? LINK_1_EN : LINK_1_JA
                                  }
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {t('organization.noteApiKeyLink')}
                                </a>
                              </div>
                            )}
                            trigger={(triggerProps) => (
                              <span
                                {...triggerProps}
                                style={{
                                  // position: 'absolute',
                                  color: 'rgb(66, 82, 110)',
                                  display: 'inline-block',
                                }}
                                onClick={() => {
                                  setIsOpenNote(true);
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    position: 'relative',
                                    top: '4px',
                                  }}
                                >
                                  <InfoIcon size='medium' />
                                </div>
                              </span>
                            )}
                          />

                          <TextField
                            {...fieldProps}
                            value={formValues.apiKey}
                            autoComplete='off'
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                apiKey: e.target.value,
                              });
                              setIsSave(false);
                            }}
                          />
                        </>
                      )}
                    </Field>
                    <Field
                      name='expirationDate'
                      label={t('auditLog.form.expirationDate')}
                      defaultValue=''
                      className={classes.formField}
                      styles={{ display: 'flex' }}
                    >
                      {({ fieldProps }) => (
                        <>
                          <IDatePicker
                            {...fieldProps}
                            defaultValue=''
                            value={formValues.expirationDate}
                            onChange={(e) => {
                              setIsValidExpirationDate(false);
                              setFormValues({
                                ...formValues,
                                expirationDate: e,
                              });
                            }}
                            minDate={minDate}
                          />
                          <LoadingButton
                            appearance='primary'
                            onClick={() => handleTestAPI()}
                            className={classes.btnTestApi}
                            isLoading={isTestApiKey}
                          >
                            {t('auditLog.form.buttonTestAPIKey')}
                          </LoadingButton>
                        </>
                      )}
                    </Field>
                    <div className={classes.message}>
                      {errorTestApi &&
                        showErrorMessage &&
                        (errorTestApi ===
                          t('auditLog.form.successTestApikey') ||
                          errorTestApi ===
                            t('auditLog.form.successCreateApikey')) && (
                          <SectionMessage appearance='success'>
                            {errorTestApi}
                          </SectionMessage>
                        )}
                    </div>
                    <div className={classes.message}>
                      {errorTestApi &&
                        showErrorMessage &&
                        errorTestApi === t('auditLog.form.wrongTestApikey') && (
                          <SectionMessage appearance='error'>
                            {errorTestApi}
                          </SectionMessage>
                        )}
                    </div>
                    <div className={classes.message}>
                      {isValidExpirationDate && (
                        <SectionMessage appearance='error'>
                          {t('auditLog.form.invalidExpirationDate')}
                        </SectionMessage>
                      )}
                    </div>
                    <div className={classes.message}>
                      {errorTestApi &&
                        showErrorMessage &&
                        errorTestApi === t('auditLog.form.warningSave') && (
                          <SectionMessage appearance='warning'>
                            {errorTestApi}
                          </SectionMessage>
                        )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button appearance='subtle' onClick={back}>
                        {t('common.cancel')}
                      </Button>
                      <LoadingButton
                        type='submit'
                        appearance='primary'
                        isLoading={submitting}
                      >
                        {t('common.save')}
                      </LoadingButton>
                    </ButtonGroup>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        </ModalTransition>
      </div>
    </div>
  );
};
export default OrganizationInforModal;
