import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { actions } from '../../redux';

const AUTO_HIDE_DURATION = 5000;

export const ALERT_TYPE = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
};

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const MessageSnackbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const snackbar = useSelector((state) => state.app.snackbar);
  const { open, type, messageKey, message } = snackbar;

  const handleClose = () => {
    dispatch(actions.closeSnackbar());
  };

  return (
    <Snackbar
      key='snackbar'
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert severity={type}>{messageKey ? t(messageKey) : message}</Alert>
    </Snackbar>
  );
};

export default MessageSnackbar;
