import React from 'react';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';

const SORT_KEY = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: '',
};

/**
 *  renderIconSort component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.key - The key of the sort. It can be 'asc' or 'desc'.
 * @returns {JSX.Element} A JSX element displaying the Atlassian icon.
 */
const renderIconSort = (key) => {
  if (!key) return <></>;
  return (
    <>
      {key === SORT_KEY.ASC ? (
        <ArrowUpIcon size='small' />
      ) : (
        <ArrowDownIcon size='small' />
      )}
    </>
  );
};

export { renderIconSort, SORT_KEY };
