import React from 'react';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '@atlaskit/button';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import Notification from '@root/components/Notification';
import Organization from '@root/components/organization/components/Organization';
import UsersBoard from '@root/components/UserManagement/UsersBoard';

const ModalInfo = (props) => {
  const {
    onSuccess = () => {},
    isOpen,
    setIsOpen,
    isUpdateData,
    setIsUpdateData,
    changedOrg,
    orgEdit,
    setOrgEdit,
  } = props;
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = React.useState(true);
  const renewData = useSelector((state) => state?.app?.renewModalData);
  const updateOrgSuccess = () => {
    newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.cannel);
    setIsOpen('');
    if (renewData === 'security') {
      onSuccess();
    }
  };
  const renderCard = () => {
    if (isOpen === 'organization#add')
      return (
        <Organization
          isOpenDialog={isOpenDialog}
          setIsOpenDialog={setIsOpenDialog}
          changedOrg={changedOrg}
          orgEdit={orgEdit}
          setOrgEdit={setOrgEdit}
          isUpdateData={isUpdateData}
          setIsUpdateData={setIsUpdateData}
        />
      );

    if (isOpen === 'organization')
      return (
        <Organization
          isOpenDialog={false}
          setIsOpenDialog={() => {}}
          changedOrg={changedOrg}
          orgEdit={orgEdit}
          setOrgEdit={setOrgEdit}
          isUpdateData={isUpdateData}
          setIsUpdateData={setIsUpdateData}
        />
      );

    if (isOpen === 'notification') return <Notification />;

    return '';
  };

  return (
    <ModalTransition>
      <Modal width='100vw' autoFocus='false' height='100%'>
        <ModalBody>{renderCard()}</ModalBody>
        <ModalFooter>
          <Button appearance='subtle' onClick={() => updateOrgSuccess()}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default ModalInfo;
