import { backEndApi } from '@root/libs';

const api = '/api/v1';
const sitePath = '/policies';

const organizationPoliciesAPIs = {
  getOrganizationPolicies: (body) => backEndApi.post(`${api}${sitePath}`, body),
  saveOrganizationPolicies: (body) =>
    backEndApi.post(`${api}${sitePath}/save`, body),
  getWorkspaceAllowList: (orgId) =>
    backEndApi.get(`${api}${sitePath}/get-workspace-options/${orgId}`),
};

export default organizationPoliciesAPIs;
