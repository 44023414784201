import React from 'react';
import LockCircleIcon from '@atlaskit/icon/glyph/lock-circle';
import UnlockCircleIcon from '@atlaskit/icon/glyph/unlock-circle';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import i18next from 'i18next';
import { makeStyles, styled } from '@material-ui/styles';
import isNumber from 'lodash/isNumber';
import { isNotEmptyArray } from '@root/utils';
import Box from '@material-ui/core/Box';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import { ACCESS_LEVEL } from '@root/utils/constant';

const useStylesViewIcons = makeStyles(() => ({
  border: {
    background: '#ffac00',
    borderRadius: '100%',
    position: 'relative',
    margin: '2px 1px 1px 1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20px',
    height: '20px',
    width: '20px',
    '& > span': {
      width: '12px',
      height: '12px',
      display: 'flex',
    },
  },
}));

export const OpenWarningIcon = () => {
  const classes = useStylesViewIcons();
  return (
    <div className={classes.border}>
      <WatchFilledIcon primaryColor='#fff' size='small' />
    </div>
  );
};

const BoxIcon = styled(Box)({
  color: '#6D6E6F',
});

export const renderAccessLevel = ({ accessLevel, total }) => (
  <BoxIcon display='flex' alignItems='center' gridGap='5px'>
    {accessLevel === ACCESS_LEVEL.PRIVATE && (
      <LockCircleIcon
        label={i18next.t(`security.${accessLevel}`)}
        primaryColor='#3F8425'
      />
    )}
    {accessLevel === ACCESS_LEVEL.OPEN && (
      <OpenWarningIcon
        label={i18next.t(`security.${accessLevel}`)}
        primaryColor='#3F8425'
      />
    )}

    {accessLevel === ACCESS_LEVEL.PUBLIC && (
      <UnlockCircleIcon
        label={i18next.t(`security.${accessLevel}`)}
        primaryColor='#C1271B'
      />
    )}
    <div>
      <span style={{ color: '#6D6E6F' }}>
        {i18next.t(`security.${accessLevel}`)}
      </span>
      {isNumber(total) ? <strong> {total}</strong> : <></>}
    </div>
  </BoxIcon>
);

const Viewers = styled('div')({
  flex: 0.2,
  fontSize: '0.9rem',
  fontWeight: 500,
  color: '#42526E',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  '& div': {
    gap: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
});

export const getAccessLevelFilterOrDashboard = (viewers) => {
  if (viewers?.some((viewer) => viewer?.type === 'global')) {
    return ACCESS_LEVEL.PUBLIC;
    // eslint-disable-next-line no-else-return
  } else if (viewers?.some((viewer) => viewer?.type === 'loggedin')) {
    return ACCESS_LEVEL.OPEN;
  } else {
    return ACCESS_LEVEL.PRIVATE;
  }
};

export const countViewerByType = (type, viewers) =>
  viewers.filter(
    (viewer) =>
      (viewer.type === 'project-unknown' ? 'project' : viewer.type) === type,
  ).length || 0;

export const transformDataSecurity = (results, siteList) => {
  const data = results.map((result) => {
    const site = siteList.find((s) => s.siteId === result.siteId);
    return {
      ...result,
      siteName: site?.name || '',
      organizationName: site.organizationName
        ? `${site.organizationName}`
        : `${i18next.t('activity.notOrgName')}`,
      urlSite: site.url,
    };
  });
  return data;
};

export const renderViewers = (viewers, VIEWER_ITEM) => {
  const viewerTypes = isNotEmptyArray(viewers)
    ? [
        ...new Set(
          viewers.map((viewer) =>
            viewer.type === 'project-unknown' ? 'project' : viewer.type,
          ),
        ),
      ]
    : [];
  return (
    <Viewers>
      {viewerTypes.map((type) => (
        <div key={VIEWER_ITEM[type]?.key}>
          <>
            {VIEWER_ITEM[type]?.icon}
            <span>
              {type !== 'private' &&
                type !== 'global' &&
                countViewerByType(type, viewers)}{' '}
              {VIEWER_ITEM[type]?.name}
            </span>
          </>
        </div>
      ))}
    </Viewers>
  );
};

export const renderIconSort = (selectedSort) => {
  if (selectedSort.key === 'siteName') {
    if (selectedSort.sortOrder === 'ASC') {
      return <ArrowUpIcon size='small' />;
    }
    if (selectedSort.sortOrder === 'DESC') {
      return <ArrowDownIcon size='small' />;
    }
  }
  if (selectedSort.key === 'organizationName') {
    if (selectedSort.sortOrder === 'ASC') {
      return <ArrowUpIcon size='small' />;
    }
    if (selectedSort.sortOrder === 'DESC') {
      return <ArrowDownIcon size='small' />;
    }
  }
  if (selectedSort.key === '') {
    return <ArrowUpIcon size='small' />;
  }
  return '';
};

export const sortFirstData = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  const checkDataType = (val) => val || '';
  const ORDER = {
    public: 1,
    open: 2,
    private: 3,
  };
  // sorted by status public sharing
  return [...data].sort((a, b) => {
    const accessLevelOrder = ORDER[a.accessLevel] - ORDER[b.accessLevel];
    if (accessLevelOrder !== 0) {
      return accessLevelOrder;
      // eslint-disable-next-line no-else-return
    } else {
      const orgComparison = checkDataType(a?.organizationName).localeCompare(
        checkDataType(b?.organizationName),
      );
      if (orgComparison !== 0) {
        return orgComparison;
        // eslint-disable-next-line no-else-return
      } else {
        return checkDataType(a?.siteName).localeCompare(
          checkDataType(b?.siteName),
        );
      }
    }
  });
};
export const extractItemInfoByKey = (key) => {
  if (key) {
    return {
      siteId: key.substring(0, key.indexOf(':')),
      itemId: key.substring(key.indexOf(':') + 1),
    };
  }
  return key;
};

export const groupDashboardIdbySite = (checkBoxData) => {
  const newData = {};
  checkBoxData.forEach((item) => {
    const [siteId, id] = item.split(':');

    if (!newData[siteId]) {
      newData[siteId] = [id];
    } else {
      newData[siteId].push(id);
    }
  });

  const formattedData = Object.keys(newData).map((key) => ({
    siteId: key,
    itemIds: newData[key],
  }));

  return formattedData;
};
export const generateItemKey = (siteId, itemId) => `${siteId}:${itemId}`;
