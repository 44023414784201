import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  organizationName: {
    color: 'rgb(0, 0, 0)',
  },
  notOrgName: {
    color: 'rgb(229, 104, 111)',
  },
  siteNameList: {
    '& span': {
      marginLeft: 5,
      borderRadius: 4,
      backgroundColor: '#dfe1e6',
      color: '#6b7283',
      padding: '2px 5px',
    },
  },
  siteOrgInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px 15px',
    flexWrap: 'wrap',
    fontSize: 14,
  },
}));
const OrgSiteInformation = ({ organizationName, siteNameList, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div
      className={
        className ? `${className} ${classes.siteOrgInfo}` : classes.siteOrgInfo
      }
    >
      <div>
        {t('activity.organizationLabel')}:{' '}
        <span
          className={`${
            organizationName ? classes.organizationName : classes.notOrgName
          }`}
        >
          {organizationName || t('activity.notOrgName')}
        </span>
      </div>
      <div className={`${classes.siteNameList}`}>
        {t('activity.siteLabel')}:
        {siteNameList.map((siteName) => (
          <span key={siteName}>{siteName}</span>
        ))}
      </div>
    </div>
  );
};
export default OrgSiteInformation;
