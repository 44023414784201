import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@root/redux';
import { ALERT_TYPE } from '@root/components/common/MessageSnackbar';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import LoadingButton from '@atlaskit/button/loading-button';
import newRelicUtils from '@root/utils/newRelicUtils';
import { organizationAPIs } from '@root/services';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { MODAL_KEY } from '@root/components/common/modal';

const OrganizationDeleteModal = ({
  isOpen = false,
  organization,
  updateOrg = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const back = () => {
    dispatch(
      actions.toggleModal({
        key: MODAL_KEY.DELETE_ORG_API_KEY,
      }),
    );
  };

  const onClickRemove = async () => {
    setIsLoading(true);
    newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.remove, {
      orgIds: organization?.atlasOrgId,
    });
    try {
      await organizationAPIs.deleteOrganizationApikey(
        organization?.atlasOrgId,
        organization?.id,
      );
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.deleteOrganization',
        }),
      );
      updateOrg();
    } catch (error) {
      console.log('err: ', error);
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.error,
          messageKey: 'snackbar.failedToDeleteOrganization',
        }),
      );
    }
    setIsLoading(false);
    back();
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={back}>
          <ModalHeader>
            <ModalTitle appearance='danger'>
              {t('organization.deleteOrganization')}
              {organization?.orgName}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>{t('organization.deleteOrganizationConfirm')}</ModalBody>
          <ModalFooter>
            <Button appearance='subtle' onClick={back} isDisabled={isLoading}>
              {t('common.cancel')}
            </Button>
            <LoadingButton
              autoFocus
              appearance='danger'
              onClick={onClickRemove}
              isLoading={isLoading}
            >
              {t('common.remove')}
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default OrganizationDeleteModal;
