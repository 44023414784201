import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '332px',
    height: '100%',
    textAlign: 'center',
  },
  title: {
    fontWeight: '700',
    fontSize: '32px',
    color: '#091E42',
    height: '700',
    marginTop: '17px',
  },
  message: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#091E42',
    height: 'normal',
    marginTop: '14px',
    whiteSpace: 'nowrap',
  },
  icon: {
    margin: '24px 0',
  },
  button: {
    cursor: 'pointer',
    background: 'linear-gradient(92.7deg, #EC6423 -20.42%, #FAD100 114.43%)',
    borderRadius: '20px',
    border: 'none',
    fontWeight: 700,
    fontSize: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '47px',
    alignItems: 'center',
    marginTop: '86px',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.85',
      color: '#FFFFFF',
    },
    color: '#F0FFF0',
  },
}));

const VerifySuccess = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoToLogin = () => {
    navigate('/login', { replace: true });
  };
  return (
    <div className={classes.container}>
      <span className={classes.title}>
        {t('auth.verifyEmail.successTitle')}
      </span>
      <div className={classes.icon}>
        <CheckCircleIcon size='xlarge' primaryColor='#53be2d' styles={{}} />
      </div>
      <span className={classes.message}>
        {t('auth.verifyEmail.successMessage')}
      </span>
      <button
        type='submit'
        className={classes.button}
        onClick={handleGoToLogin}
      >
        {t('auth.login.login')}
      </button>
    </div>
  );
};

export default VerifySuccess;
