import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { siteNotificationApis } from '@root/services';
import i18next from 'i18next';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { LoadingSpinner } from '@root/components/common/icons';
import NotificationTable from './components/NotificationTable';
import commonNotificationStyles from './styles/commonStyles';
import PageContainer from '../layout/PageContainer';

const Notification = () => {
  const classes = commonNotificationStyles();
  const loadingMenu = useSelector((state) => state.site.loadingMenu);
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const siteList = useSelector((state) => state?.site?.siteList);

  const fetchAllNotificationSettings = async () => {
    setLoadingData(true);
    if (siteList.length > 0) {
      const siteIds = siteList.map((site) => site.id).join(',');
      const response =
        await siteNotificationApis.getSitesWithNotificationSetting({ siteIds });
      if (response.status === 200) {
        const siteWithSettings = response?.data?.data;
        const settings = siteList.map((site) => {
          const siteNotification = siteWithSettings.find(
            (s) => s.siteId === site.id,
          );
          if (siteNotification) {
            return {
              ...siteNotification,
              siteName: site.name,
              organizationName: site.organizationName
                ? site.organizationName
                : i18next.t('activity.orgNotAdd').toUpperCase(),
              organizationId: site.organization_id,
            };
          }
          return {
            siteId: site.id,
            siteName: site.name,
            organizationName: site.organizationName
              ? site.organizationName
              : i18next.t('activity.orgNotAdd').toUpperCase(),
            status: false,
            organizationId: site.organization_id,
            enabledFeatures: [],
            excludedProject: [],
            excludedFilter: [],
            excludedDashboard: [],
            excludedSpace: [],
            notificationEmail: null,
            notificationTime: null,
          };
        });

        setNotificationSettings(settings);
      }
    }
    setLoadingData(false);
  };

  useEffect(() => {
    (async () => {
      await fetchAllNotificationSettings();
      newRelicUtils.addPageAction(PAGE_ACTION.notification.view, {});
    })();
  }, [JSON.stringify(siteList)]);

  if (loadingMenu) {
    return <LoadingSpinner />;
  }

  return (
    <PageContainer>
      <NotificationTable
        notificationSettings={notificationSettings}
        fetchAllNotificationSettings={fetchAllNotificationSettings}
        loadingData={loadingData}
      />
    </PageContainer>
  );
};

export default Notification;
