import React from 'react';
import {
  JiraIcon,
  ConfluenceIcon,
  JiraProductDiscoveryIcon,
  JiraServiceManagementIcon,
  JiraWorkManagementIcon,
} from '@atlaskit/logo';
import { ADDON_INSTALL_PRODUCT } from '@root/utils';

/**
 *  AtlasIcon component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.typeKey - The type of Atlassian product.
 * @param {string} props.size - The size of the icon.
 * @param {string} props.appearance - The appearance of the icon.
 * @returns {JSX.Element} A JSX element displaying the Atlassian icon.
 */
const AtlassianIcon = ({
  typeKey = ADDON_INSTALL_PRODUCT.JIRA,
  size = 'medium',
  appearance = 'brand',
}) => {
  switch (typeKey) {
    case ADDON_INSTALL_PRODUCT.CONFLUENCE:
      return <ConfluenceIcon appearance={appearance} size={size} />;
    case ADDON_INSTALL_PRODUCT.JIRA_SERVICE_DESK:
    case ADDON_INSTALL_PRODUCT.JIRA_SERVICEDESK:
      return <JiraServiceManagementIcon appearance={appearance} size={size} />;
    case ADDON_INSTALL_PRODUCT.JIRA_PRODUCT_DISCOVERY:
      return <JiraProductDiscoveryIcon appearance={appearance} size={size} />;
    case ADDON_INSTALL_PRODUCT.JIRA_CORE:
      return <JiraWorkManagementIcon appearance={appearance} size={size} />;
    case ADDON_INSTALL_PRODUCT.JIRA:
    case ADDON_INSTALL_PRODUCT.JIRA_SOFTWARE:
    default:
      return <JiraIcon appearance={appearance} size={size} />;
  }
};

export { AtlassianIcon };
