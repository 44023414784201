import React, { useEffect } from 'react';
import i18n from '@root/translations/i18n';

const Language = {
  Japan: 'ja',
  English: 'en',
};

const withBaseUrlLanguageDetector = (WrappedComponent) => (props) => {
  const query = new URLSearchParams(window.location.search);
  const browserLang = window.navigator.language;
  const defaultLang =
    browserLang === Language.Japan ? browserLang : Language.English;
  const lang = query.get('lang') || defaultLang;
  const currentLang = i18n.language;

  useEffect(() => {
    if (lang === currentLang) return;
    i18n.changeLanguage(lang);
  }, [lang, defaultLang]);
  return <WrappedComponent {...props} />;
};

export default withBaseUrlLanguageDetector;
