import React from 'react';
import ContentLoader from 'react-content-loader';

const ProjectContentLoader = () => {
  const height = 230;
  return (
    <>
      {[1, 2, 3].map((v) => (
        <ContentLoader
          key={v}
          width='100%'
          height={height}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='5' ry='5' width='100' height='100' />
          <rect x='70%' y='0' rx='4' ry='4' width='30%' height='25' />
          <circle cx='80%' cy='33%' r='25' />
          <rect x='0' y='150' rx='4' ry='4' width='100%' height='15' />
          <rect x='0' y='180' rx='4' ry='4' width='100%' height='15' />
        </ContentLoader>
      ))}
    </>
  );
};
export default ProjectContentLoader;
