import { backEndApi } from '@root/libs';

const api = '/api/v1';
const sitePath = '/organization';

const organizationAPIs = {
  // Return uniq organization id list
  getAllOrganization: () => backEndApi.get(`${api}${sitePath}`),
  getAllOrganizationsAdmin: () => backEndApi.get(`${api}${sitePath}/admin`),
  // Return all organization api keys
  getAllOrganizationAPIKeys: () => backEndApi.get(`${api}${sitePath}/apikeys`),
  getOrganizationAPIByOrgId: (orgId) =>
    backEndApi.get(`${api}${sitePath}/${orgId}/api-keys`),
  getAllDeletedOrganization: () => backEndApi.get(`${api}${sitePath}/deleted`),
  checkExistApikey: (data) =>
    backEndApi.post(`${api}${sitePath}/apikey/check`, data),
  createOrganizationApikey: (body) =>
    backEndApi.post(`${api}${sitePath}/apikey`, body),
  updateOrganizationApikey: (body) =>
    backEndApi.put(`${api}${sitePath}/apikey`, body),
  validOrganizationApikey: (body) =>
    backEndApi.post(`${api}${sitePath}/valid`, body),
  deleteOrganizationApikey: (orgId, orgApikeyId) =>
    backEndApi.delete(`${api}${sitePath}/${orgId}/id/${orgApikeyId}`),
  checkIsAvailableOrgApiKey: (body) =>
    backEndApi.post(`${api}${sitePath}/apikey/check-available`, body),
  checkApiKeysForOrgIds: (body) =>
    backEndApi.post(`${api}${sitePath}/user-organizations/check-apikey`, body),
};

export default organizationAPIs;
