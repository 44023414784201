/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Tooltip from '@atlaskit/tooltip';
import {
  DEFAULT_ROW_PER_PAGE,
  NOTIFICATION_ACCESS_LEVEL,
  NOTIFICATION_FEATURE,
  ROW_PER_PAGE_OPTIONS,
  emptyTable,
  isSiteDisable,
  toQueryString,
} from '@root/utils';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import Select from '@atlaskit/select';
import PaginationTotalNumber from '@root/components/common/PaginationTotalNumber';
import Textfield from '@atlaskit/textfield';
import Pagination from '@atlaskit/pagination';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { useSelector } from 'react-redux';
import { renderIconSort } from '@root/components/security/helpers';
import Toggle from '@atlaskit/toggle';
import { CollapseSection } from '@root/components/common/section';
import NotificationEditModal from './NotificationEditModal';
import commonStyles from '../styles/commonStyles';
import { changeTimeToUserTime } from '../common';

const NotificationTable = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editingSite, setEditingSite] = useState();
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);
  const siteList = useSelector((state) => state.site.siteList);
  const [dataFinal, setDataFinal] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(
    query.get('keyword') || '',
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedSort, setSelectedSort] = useState({
    key: 'organizationName',
    sortOrder: 'ASC',
  });
  const classes = { ...commonStyles() };
  const [filterValues, setFilterValues] = useState({
    keyword: query.get('keyword') || '',
  });
  const [listSiteDisable, setListSiteDisable] = useState([]);
  const { notificationSettings, fetchAllNotificationSettings, loadingData } =
    props;

  const handleSearch = (key, value) => {
    let newFilter = { ...filterValues };
    newFilter = {
      ...filterValues,
      [key]:
        typeof value === 'string' && value ? value.toLowerCase().trim() : value,
    };
    if (query.get('lang')) {
      newFilter = { ...newFilter, lang: query.get('lang') };
    }
    setFilterValues(newFilter);
    window.history.pushState(
      '',
      '',
      `/admin-center/notification?${toQueryString(newFilter)}`,
    );
  };

  const openModal = async (siteId) => {
    const site = siteList.find((item) => item.id === siteId);
    setEditingSite({
      siteId: site.siteId,
      siteName: site.name,
      organizationName: site.organizationName
        ? site.organizationName
        : t('activity.orgNotAdd').toUpperCase(),
      organizationId: site.organization_id,
    });
    setIsOpen(true);
  };
  const closeModal = () => {
    setEditingSite();
    setIsOpen(false);
  };

  const handleDataFinal = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    const checkDataType = (val) => (typeof val !== 'string' ? '' : val);
    return [...data].sort((a, b) => {
      if (selectedSort.key) {
        return selectedSort.sortOrder === 'ASC'
          ? checkDataType(a?.[selectedSort.key]).localeCompare(
              checkDataType(b?.[selectedSort.key]),
            )
          : checkDataType(b?.[selectedSort.key]).localeCompare(
              checkDataType(a?.[selectedSort.key]),
            );
      }
      return data;
    });
  };

  const formatJiraConfluenceFeature = (tag, features, excludedIds) => {
    const nameFeature = features[0].split('-')[0];
    const listAccessLevelText = features.map((item) => {
      const accessLevel = item.split('-')[1];
      if (accessLevel === NOTIFICATION_ACCESS_LEVEL.PUBLIC) {
        return t('notification.public');
      }
      if (accessLevel === NOTIFICATION_ACCESS_LEVEL.OPEN) {
        return t('notification.open');
      }
      return t('notification.warning');
    });
    if (excludedIds.length > 0) {
      let excludedText = '';
      switch (nameFeature) {
        case NOTIFICATION_FEATURE.JIRA_PROJECT:
          excludedText = `${t('notification.excludedProjects')} (${
            excludedIds.length
          })`;
          break;
        case NOTIFICATION_FEATURE.JIRA_DASHBOARD:
          excludedText = `${t('notification.excludedDashboards')} (${
            excludedIds.length
          })`;
          break;
        case NOTIFICATION_FEATURE.JIRA_FILTER:
          excludedText = `${t('notification.excludedFilters')} (${
            excludedIds.length
          })`;
          break;
        case NOTIFICATION_FEATURE.CONFLUENCE_SPACE:
          excludedText = `${t('notification.excludedSpaces')} (${
            excludedIds.length
          })`;
          break;
        default:
          break;
      }
      return `${tag}: ${[...listAccessLevelText, excludedText].join(', ')}`;
    }
    return `${tag}: ${listAccessLevelText.join(', ')}`;
  };

  const renderNotificationOptions = (data) => {
    const {
      enabledFeatures: features,
      excludedProjectId,
      excludedDashboardId,
      excludedFilterId,
      excludedSpaceId,
    } = data;
    const listFeature = [];
    if (features.includes(NOTIFICATION_FEATURE.JIRA_SYSTEM)) {
      listFeature.push(t('notification.jiraSystem'));
    }

    if (
      features.some((item) =>
        item.startsWith(NOTIFICATION_FEATURE.JIRA_PROJECT),
      )
    ) {
      const projectFeatures = features.filter((item) =>
        item.startsWith(NOTIFICATION_FEATURE.JIRA_PROJECT),
      );
      const formattedProjectFeature = formatJiraConfluenceFeature(
        t('notification.jiraProject'),
        projectFeatures,
        excludedProjectId,
      );
      listFeature.push(formattedProjectFeature);
    }

    if (
      features.some((item) =>
        item.startsWith(NOTIFICATION_FEATURE.JIRA_DASHBOARD),
      )
    ) {
      const dashboardFeature = features.filter((item) =>
        item.startsWith(NOTIFICATION_FEATURE.JIRA_DASHBOARD),
      );
      const formattedDashboardFeature = formatJiraConfluenceFeature(
        t('notification.jiraDashboard'),
        dashboardFeature,
        excludedDashboardId,
      );
      listFeature.push(formattedDashboardFeature);
    }

    if (
      features.some((item) => item.startsWith(NOTIFICATION_FEATURE.JIRA_FILTER))
    ) {
      const filterFeatures = features.filter((item) =>
        item.startsWith(NOTIFICATION_FEATURE.JIRA_FILTER),
      );
      const formattedFilterFeature = formatJiraConfluenceFeature(
        t('notification.jiraFilter'),
        filterFeatures,
        excludedFilterId,
      );
      listFeature.push(formattedFilterFeature);
    }

    if (
      features.some((item) =>
        item.startsWith(NOTIFICATION_FEATURE.CONFLUENCE_SPACE),
      )
    ) {
      const spaceFeatures = features.filter((item) =>
        item.startsWith(NOTIFICATION_FEATURE.CONFLUENCE_SPACE),
      );
      const formattedSpaceFeature = formatJiraConfluenceFeature(
        t('notification.confluenceSpace'),
        spaceFeatures,
        excludedSpaceId,
      );
      listFeature.push(formattedSpaceFeature);
    }

    // if (features.includes(NOTIFICATION_FEATURE.PRODUCT_CHANGE)) {
    //   listFeature.push(t('notification.changeOfProducts'))
    // }

    // if (features.includes(NOTIFICATION_FEATURE.PLUGIN_CHANGE)) {
    //   listFeature.push(t('notification.changeOfPlugins'))
    // }
    return listFeature;
  };

  const totalPages = useMemo(
    () =>
      Math.ceil(dataFinal?.length / (rowsPerPage || DEFAULT_ROW_PER_PAGE)) || 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(dataFinal), rowsPerPage],
  );
  const header = {
    key: '',
    cells: [
      {
        key: 'organizationName',
        content: (
          <div className={classes.jiraheader}>
            <Tooltip content={t('common.sort')}>
              {(tooltipProps) => (
                <div
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...tooltipProps}
                  style={{ paddingRight: '5px', cursor: 'pointer', flex: 1 }}
                  role='button'
                  tabIndex={0}
                  onKeyPress={noop}
                  onClick={() => {
                    if (selectedSort.key === 'organizationName') {
                      setSelectedSort({
                        key: 'organizationName',
                        sortOrder:
                          selectedSort.sortOrder === 'ASC' ? 'DESC' : 'ASC',
                      });
                    } else {
                      setSelectedSort({
                        key: 'organizationName',
                        sortOrder: 'ASC',
                      });
                    }
                  }}
                >
                  {selectedSort.key === 'organizationName' &&
                    renderIconSort(selectedSort)}
                  {t('activity.organizationLabel')}
                </div>
              )}
            </Tooltip>
          </div>
        ),
        width: 8,
      },
      {
        key: 'siteName',
        content: (
          <div className={classes.jiraheader}>
            <Tooltip content={t('common.sort')}>
              {(tooltipProps) => (
                <div
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...tooltipProps}
                  style={{ paddingRight: '5px', cursor: 'pointer', flex: 1 }}
                  role='button'
                  tabIndex={0}
                  onKeyPress={noop}
                  onClick={() => {
                    if (selectedSort.key === 'siteName') {
                      setSelectedSort({
                        key: 'siteName',
                        sortOrder:
                          selectedSort.sortOrder === 'ASC' ? 'DESC' : 'ASC',
                      });
                    } else {
                      setSelectedSort({
                        key: 'siteName',
                        sortOrder: 'ASC',
                      });
                    }
                  }}
                >
                  {selectedSort.key === 'siteName' &&
                    renderIconSort(selectedSort)}
                  {t('activity.siteLabel')}
                </div>
              )}
            </Tooltip>
          </div>
        ),
        width: 9,
      },
      {
        key: 'notificationStatus',
        content: (
          <div className={classes.jiraheader}>
            {t('notification.enable')}/{t('notification.disable')}
          </div>
        ),
        width: 6,
      },
      {
        key: 'enabledFeatures',
        content: (
          <div className={classes.jiraheader}>
            {t('notification.notificationOptions')}
          </div>
        ),
        width: 42,
      },
      {
        key: 'notificationTime',
        content: (
          <div className={classes.jiraheader}>
            {t('notification.notificationTime')}
          </div>
        ),
        width: 10,
      },
      {
        key: 'notificationEmail',
        content: (
          <div className={classes.jiraheader}>
            {t('notification.notificationEmail')}
          </div>
        ),
        width: 15,
      },
      {
        key: 'Actions',
        content: (
          <div className={classes.headerOrgLastCol}>
            <span className={classes.action}>{t('organization.actions')}</span>
          </div>
        ),
        width: 10,
      },
    ],
  };

  const generateDisabledClass = (setting) => {
    if (listSiteDisable.find((site) => site.id === setting.siteId)) {
      return classes.disabledCell;
    }
    return '';
  };

  const rows = handleDataFinal(dataFinal).map((data, key) => {
    const datarow = {
      key,
      cells: [
        {
          key: data.organizationName,
          content: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 120,
                fontWeight: 500,
              }}
              className={generateDisabledClass(data)}
            >
              {data.organizationName}
            </div>
          ),
        },
        {
          key: data.siteName,
          content: (
            <>
              {!listSiteDisable.find((site) => site.id === data.siteId) ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 120,
                    fontWeight: 500,
                  }}
                  className={generateDisabledClass(data)}
                >
                  {data.siteName}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 120,
                    fontWeight: 500,
                  }}
                  className={generateDisabledClass(data)}
                >
                  <WarningIcon primaryColor='#E5686F' />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 4,
                    }}
                  >
                    <div>{data.siteName}</div>
                    <div style={{ color: '#E5686F', fontSize: 10 }}>
                      {t('common.licenseOutdate')}
                    </div>
                  </div>
                </div>
              )}
            </>
          ),
        },
        {
          key: data.status,
          content: (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 60,
                  fontWeight: 500,
                }}
                className={generateDisabledClass(data)}
              >
                <Toggle size='large' isChecked={data.status === 'enabled'} />
              </div>
            </>
          ),
        },
        {
          key: data?.enabledFeatures,
          content: (
            <div
              style={{
                minWidth: 190,
                fontWeight: 500,
              }}
              className={`${classes.fixedHeightColumn} ${generateDisabledClass(
                data,
              )}`}
            >
              {renderNotificationOptions(data).map((feature) => (
                <span key={feature} className={classes.labelAddress}>
                  {feature}
                </span>
              ))}
            </div>
          ),
        },
        {
          key: data.notificationTime,
          content: (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 190,
                  fontWeight: 500,
                }}
                className={generateDisabledClass(data)}
              >
                {data.notificationTime !== null
                  ? `${String(
                      changeTimeToUserTime(data.notificationTime),
                    ).padStart(2, '0')}:00`
                  : ''}
              </div>
            </>
          ),
        },
        {
          key: data.notificationEmail,
          content: (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 190,
                  fontWeight: 500,
                }}
                className={generateDisabledClass(data)}
              >
                {data.notificationEmail ? data.notificationEmail : ''}
              </div>
            </>
          ),
        },
        {
          key: data.siteId,
          content: (
            <>
              <Button
                appearance='link'
                onClick={() => openModal(data.siteId)}
                className={`${classes.editBtn} ${generateDisabledClass(data)}`}
                isDisabled={listSiteDisable.find(
                  (site) => site.id === data.siteId,
                )}
              >
                {t('common.edit')}
              </Button>
            </>
          ),
        },
      ],
    };

    return datarow;
  });

  useEffect(() => {
    const { keyword } = filterValues;
    if (keyword) {
      const filteredSettings = notificationSettings.filter((item) => {
        const { siteName } = item;
        return siteName.toLowerCase().includes(keyword.toLowerCase());
      });
      setDataFinal(filteredSettings);
    } else {
      setDataFinal(notificationSettings);
    }
    setCurrentPage(1);
  }, [JSON.stringify(notificationSettings), filterValues]);

  useEffect(() => {
    const sitesDisable = siteList.filter((site) => isSiteDisable(site));
    if (sitesDisable) {
      setListSiteDisable(sitesDisable);
    }
  }, [siteList]);

  return (
    <CollapseSection
      hideExpandedIcon
      defaultOpen
      id='activityUser'
      renderTitle={() => (
        <h4 style={{ fontSize: '24px', marginBottom: '20px' }}>
          {t('notification.notification')}
        </h4>
      )}
    >
      <div className={classes.searchContainer}>
        <div className={classes.searchWrapper}>
          <div className={classes.searchItem}>
            <Textfield
              elemBeforeInput={
                <EditorSearchIcon
                  label='search icon'
                  primaryColor='rgb(94, 108, 132)'
                />
              }
              value={searchKeyword}
              name='keyword'
              isCompact
              width='300'
              height='40'
              autoFocus={false}
              placeholder={t('notification.searchPlaceholder')}
              fontSize={16}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSearch('keyword', e.target.value);
              }}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>

        <div className={classes.rightAction}>
          {
            <>
              <Select
                // blurInputOnSelect
                className={classes.rowsPerPageOpt}
                value={ROW_PER_PAGE_OPTIONS().find(
                  (option) => option.value === rowsPerPage,
                )}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setRowsPerPage(selectedOption.value);
                }}
                isSearchable={false}
                options={ROW_PER_PAGE_OPTIONS()}
              />
              <PaginationTotalNumber
                currentPage={currentPage}
                actualItem={dataFinal.length}
                rowsPerPage={rowsPerPage}
                totalPage={dataFinal.length}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          }
        </div>
      </div>
      <div className={classes.tableResponsive}>
        <DynamicTable
          head={header}
          rows={rows}
          emptyView={emptyTable()}
          isLoading={loadingData}
          rowsPerPage={rowsPerPage}
          page={currentPage}
        />
      </div>
      {dataFinal.length !== 0 && (
        <div className={classes.paginationStyle}>
          <Pagination
            defaultSelectedIndex={currentPage - 1}
            selectedIndex={currentPage - 1}
            pages={Array.from(
              {
                length: totalPages,
              },
              (_v, i) => i + 1,
            )}
            onChange={(data, page) => setCurrentPage(page)}
          />
        </div>
      )}
      {isOpen && editingSite && (
        <NotificationEditModal
          isOpen={isOpen}
          editingSite={editingSite}
          closeModal={closeModal}
          fetchAllNotificationSettings={fetchAllNotificationSettings}
        />
      )}
    </CollapseSection>
  );
};

export default NotificationTable;
