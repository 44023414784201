import React from 'react';
import { makeStyles } from '@material-ui/styles';

const HEADER_HEIGHT = process.env.SHOW_RELEASE_NOTICE === 'true' ? 106 : 60;

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT + 20}px)`,
    display: 'flex',
    flexDirection: 'column',
    marginTop: `${HEADER_HEIGHT + 20}px`,
    padding: '0 3rem',
  },
}));
const PageContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default PageContainer;
