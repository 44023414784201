/* eslint-disable react/react-in-jsx-scope */
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import mfaAPIs from '@root/services/mfaApis';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Spinner from '@atlaskit/spinner';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import logo from '../../images/d_accel_yoko.png';
import { AuthContext } from './AuthProvider';
import SetupDeviceNotificationModal from './SetupDeviceNotificationModal';

const useStyles = makeStyles(() => ({
  mfaForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '332px',
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '55px',
  },
  logo: {
    width: '192px',
  },
  title: {
    color: '#091E42',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '17px',
  },
  instructionList: {
    marginTop: '21px',
    marginBottom: '10px',
    fontSize: '14px',
    paddingLeft: '16px',
  },
  instructionItem: {
    marginBottom: '11px',
    textAlign: 'justify',
  },
  qrCodeImg: {
    maxHeight: '180px',
  },
  qrCode: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '14px',
  },
  inputLabel: {
    color: '#6D6E6F',
    fontSize: '14px',
    fontWeight: '400',
  },
  input: {
    '& > input': {
      marginTop: '3px',
      color: '#000',
      border: '1px solid #C1C7D0',
      height: '40px',
      fontSize: '14px',
      background: ' #F5F5F5',
      boxSizing: 'border-box',
      fontWeight: 500,
      borderRadius: '40px',
      padding: '0 10px 1px 18px',
      '&:focus': {
        border: '1px solid #FAD100',
        background: '#F5F5F5 !important',
      },
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
    '& > select': {
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
          boxShadow: '0 0 0 2px #fad10042 !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
  },
  enableBtn: {
    marginTop: '4px',
    cursor: 'pointer',
    background: 'linear-gradient(92.7deg, #EC6423 -20.42%, #FAD100 114.43%)',
    borderRadius: '40px',
    border: 'none',
    fontWeight: 700,
    fontSize: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '40px',
    alignItems: 'center',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.85',
      color: '#FFFFFF',
    },
    color: '#F0FFF0',
  },
  backToLogIn: {
    display: 'flex',
    fontWeight: '500',
    fontSize: '14px',
    marginTop: '36px',
    color: '#8E8E8E',
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingBottom: '30px',
    '&:hover': {
      opacity: '0.8',
    },
  },
  errorMessageContainer: {
    color: '#D92929',
    fontSize: '16px',
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
  },
  errorMessage: {
    marginLeft: '5px',
  },
}));

const SetupDeviceMFA = ({ backToLogInScreen, userEmail }) => {
  const { logout } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [mfa1, setMfa1] = React.useState('');
  // const [mfa2, setMfa2] = React.useState('')
  const [image, setImage] = React.useState(null);
  const [errorCode, setErrorCode] = React.useState(null);
  const [isSuccessSetup, setIsSuccessSetup] = React.useState(false);

  const backToLogIn = (event) => {
    event.preventDefault();
    backToLogInScreen();
  };

  const getQRCode = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const body = {
        accessToken,
        userEmail,
      };
      const response = await mfaAPIs.setupDeviceMFA(body);
      setImage(response.data.raw.qrCode);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getQRCode();
  }, []);

  const handleEnableMFA = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem('accessToken');
      const body = {
        accessToken,
        userCode: mfa1,
      };
      const verifyDevice = await mfaAPIs.verifyDeviceMFA(body);
      newRelicUtils.addPageAction(PAGE_ACTION.login.mfaRegister, {
        userEmail,
        userId: null,
      });
      if (verifyDevice.data.success) {
        setIsSuccessSetup(true);
      } else {
        setErrorCode(verifyDevice.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  const handleNavigateLogin = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    logout();
    navigate('/', { replace: true });
  };

  return (
    <form className={classes.mfaForm} onSubmit={handleEnableMFA}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt='Daccel' />
      </div>

      <span className={classes.title}>{t('mfa.settings.title')}</span>

      <ol className={classes.instructionList} type='1'>
        <li className={classes.instructionItem}>{t('mfa.instructionStep1')}</li>

        <li className={classes.instructionItem}>
          {t('mfa.instructionStep2')}
          <div className={classes.qrCode}>
            {image && (
              <img src={image} alt='Base64' className={classes.qrCodeImg} />
            )}
          </div>
        </li>

        <li className={classes.instructionItem}>{t('mfa.instructionStep3')}</li>
      </ol>

      <div className={classes.inputField}>
        <span className={classes.inputLabel}>{t('mfa.settings.mfaCode1')}</span>
        <Input
          required
          disabled={isLoading}
          className={classes.input}
          disableUnderline
          value={mfa1}
          onChange={(event) => setMfa1(event.target.value)}
          onBlur={() => setMfa1(mfa1.trim())}
        />
      </div>

      {/* <div className={classes.inputField}>
        <span className={classes.inputLabel}>MFA code 2</span>
        <Input
          required
          disabled={isLoading}
          className={classes.input}
          disableUnderline
          value={mfa2}
          onChange={(event) => setMfa2(event.target.value)}
          onBlur={() => setMfa2(mfa2.trim())}
        />
      </div> */}
      {errorCode && (
        <div className={classes.errorMessageContainer}>
          <WarningIcon />
          <span className={classes.errorMessage}>{errorCode}</span>
        </div>
      )}
      <button
        type='submit'
        className={classes.enableBtn}
        disabled={isLoading}
        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
      >
        {isLoading ? <Spinner /> : t('mfa.settings.enable')}
      </button>
      {isSuccessSetup && (
        <SetupDeviceNotificationModal onClose={handleNavigateLogin} />
      )}
      <span onClick={backToLogIn} className={classes.backToLogIn}>
        {t('auth.forgotPassword.back')}
      </span>
    </form>
  );
};

export default SetupDeviceMFA;
