import React from 'react';
import { Field as FieldAtlas } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';

const Field = ({
  name,
  label,
  value,
  onChange,
  defaultValue,
  renderEndButton,
  isRequired = true,
  type = 'text',
  ...props
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <FieldAtlas
      name={name}
      label={label}
      defaultValue={defaultValue}
      {...props}
    >
      {({ fieldProps }) => (
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <TextField
            {...fieldProps}
            value={value}
            autoComplete={type === 'password' ? 'new-password' : 'off'}
            isRequired={isRequired}
            onChange={handleOnChange}
            type={type}
          />
          {renderEndButton?.()}
        </div>
      )}
    </FieldAtlas>
  );
};

export { Field };
