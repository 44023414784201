import React from 'react';
import { Field, ValidMessage, ErrorMessage } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { styled } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { regexEmail } from '@root/utils/regexUtils';

const AlertBox = styled('div')({
  '&> *': {
    alignItems: 'center',
  },
});

const EmailTextField = ({ email, setEmail, ...props }) => {
  const { t } = useTranslation();
  const [fieldHasError, setFieldHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const validateEmail = (value) => {
    setEmail(value);
    if (!value || regexEmail.test(String(value).toLowerCase())) {
      setFieldHasError(false);
    } else {
      setErrorMessage(t('form.validate.emailInvalid'));
      return 'INCORRECT_PHRASE';
    }
    return undefined;
  };

  const handleBlurEvent = () => {
    if (email && !regexEmail.test(email)) {
      setFieldHasError(true);
    }
  };

  return (
    <Field validate={validateEmail} isRequired {...props}>
      {({ fieldProps, meta: { valid } }) => (
        <>
          <TextField
            type='email'
            placeholder={t('form.placeholder.inviteEmail')}
            value={email}
            {...fieldProps}
            onBlur={handleBlurEvent}
          />
          <AlertBox>
            {email && valid && (
              <ValidMessage>{t('form.validate.emailValid')}</ValidMessage>
            )}
            {fieldHasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {!(email && valid) && !fieldHasError && <br />}
          </AlertBox>
        </>
      )}
    </Field>
  );
};

export { EmailTextField };
