import React from 'react';
import Spinner from '@atlaskit/spinner';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: { flexGrow: 1 },
  connectCognito: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
const DXLoadingScreen = () => {
  const classes = useStyles();
  return (
    <div className={classes.connectCognito}>
      <h2>Connect DX App</h2>
      <Spinner size='medium' />
    </div>
  );
};

export default DXLoadingScreen;
