import { backEndApi } from '@root/libs';

const api = '/api/v1';
const siteNotificationPath = '/notifications';

const siteNotificationApis = {
  getSitesWithNotificationSetting: ({ siteIds }) =>
    backEndApi.get(
      `${api}${siteNotificationPath}/sites/all-settings?siteIds=${siteIds}`,
    ),
  getDetailSiteNotificationSetting: ({ siteId }) =>
    backEndApi.get(`${api}${siteNotificationPath}/site/${siteId}/setting`),
  saveSiteNotificationSetting: ({ siteId, setting }) =>
    backEndApi.post(`${api}${siteNotificationPath}/site/${siteId}/save`, {
      setting,
    }),
};

export default siteNotificationApis;
