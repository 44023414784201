/* eslint-disable no-async-promise-executor */
// eslint-disable-next-line import/no-cycle
import mfaAPIs from '@root/services/mfaApis';
import {
  ACCESS_TOKEN_KEY,
  LogginCheckStatus,
  REFRESH_TOKEN_KEY,
  updateLastLoginDate,
} from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import React, { createContext, useState } from 'react';
import Pool from './UserPool';

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = React.useState(
    LogginCheckStatus.NOT_CHECK_YET,
  );
  const [
    showSuccessfulChangePasswordNoti,
    setShowSuccessfulChangePasswordNoti,
  ] = useState(false);

  const logout = () => {
    const user = Pool.getCurrentUser();
    newRelicUtils.addPageAction(PAGE_ACTION.login.out);
    if (user) {
      user.signOut();
      setLoggedIn(LogginCheckStatus.NOT_CHECK_YET);
    }
  };

  const getSession = async () => {
    await new Promise(async (resolve, reject) => {
      const user = Pool.getCurrentUser();
      const location = window.location.pathname;

      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
            setLoggedIn(LogginCheckStatus.FAIL);
          } else {
            const userId = user.signInUserSession.accessToken.payload.sub;
            if (location === '/') {
              const { data: mfaReponse } = await mfaAPIs.getMfaStatus(userId);
              const userMfaPreference = mfaReponse?.raw;
              if (
                !userMfaPreference?.mfaStatus &&
                userMfaPreference?.mfaRequired
              ) {
                // Do not allow user to login if user is required MFA login and haven't setup MFA device
                logout();
                reject();
              } else {
                resolve(session);
                setLoggedIn(LogginCheckStatus.SUCCESS);
              }
            } else {
              resolve(session);
              setLoggedIn(LogginCheckStatus.SUCCESS);
            }
          }
        });
      } else {
        reject();
        setLoggedIn(LogginCheckStatus.FAIL);
      }
    });
  };

  const authenticate = async (Username, Password) => {
    const response = await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {
          const res = {
            data,
            newPasswordRequired: false,
            user,
          };
          resolve(res);
        },
        onFailure: (err) => {
          console.error('onFailure:', err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          const res = {
            data,
            user,
            newPasswordRequired: true,
          };
          resolve(res);
        },
        totpRequired: () => {
          const res = {
            user,
            totpRequired: true,
          };
          resolve(res);
        },
      });
    });
    return response;
  };

  const forgotPassword = async (email) => {
    const response = await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: email.toLowerCase(), Pool });
      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          // console.error('onFailure:', err)
          reject(err);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        },
      });
    });

    return response;
  };

  const confirmPassword = async (email, verficationCode, newPassword) => {
    const response = await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: email.toLowerCase(), Pool });
      user.confirmPassword(verficationCode, newPassword, {
        onSuccess: (data) => {
          // console.log('Password confirmed')
          resolve(data);
        },
        onFailure: (error) => {
          // console.error('Password not confirmed')
          // console.log(error)
          reject(error);
        },
      });
    });
    return response;
  };

  const changePassword = async (email, oldPassword, newPassword) => {
    const response = await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: email.toLowerCase(), Pool });
      user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
    return response;
  };

  const completeNewPasswordChallenge = async (
    user,
    newPassword,
    sessionUserAttributes,
  ) => {
    const response = await new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });

    return response;
  };

  const sendMFACode = async (user, totpCode) => {
    const response = await new Promise((resolve, reject) => {
      user.sendMFACode(
        totpCode,
        {
          onSuccess: (result) => {
            localStorage.setItem(ACCESS_TOKEN_KEY, result.accessToken.jwtToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, result.refreshToken.token);
            updateLastLoginDate(new Date());
            setLoggedIn(LogginCheckStatus.SUCCESS);
            window.location.href = `${window.location.origin}/`;
            resolve(result);
          },
          onFailure: (err) => {
            setLoggedIn(LogginCheckStatus.FAIL);
            reject(err);
          },
        },
        'SOFTWARE_TOKEN_MFA',
      );
    });

    return response;
  };

  const getUserAttributes = async (user) => {
    const response = await new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          console.error(err);
        } else {
          // Find the UserMfaPreference attribute in the user attributes
          const mfaPreferenceAttribute = attributes.find(
            (attr) => attr.getName() === 'UserMfaPreference',
          );

          if (mfaPreferenceAttribute) {
            const userMfaPreference = mfaPreferenceAttribute.getValue();
            console.log('User MFA Preference:', userMfaPreference);
          } else {
            console.log('User MFA Preference not found.');
          }
        }
      });
    });

    return response;
  };

  const value = {
    loggedIn,
    authenticate,
    getSession,
    getUserAttributes,
    logout,
    forgotPassword,
    confirmPassword,
    changePassword,
    completeNewPasswordChallenge,
    showSuccessfulChangePasswordNoti,
    setShowSuccessfulChangePasswordNoti,
    sendMFACode,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
