import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import Select, { components } from '@atlaskit/select';
import { makeStyles } from '@material-ui/styles';
import { Field, HelperMessage, ErrorMessage } from '@atlaskit/form';
import { regexEmail } from '@root/utils/regexUtils';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
  },
}));

const DropdownIndicatorNone = (fieldsProps) => (
  <components.DropdownIndicator {...fieldsProps}>
    <></>
  </components.DropdownIndicator>
);

const MultipleEmailTextField = ({
  name = '',
  label = '',
  placeholder,
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [values, setValues] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');

  const handleChangeValue = () => {
    const stringWithoutSpaces = inputValue.replace(/\s+/g, '');
    const listValue = stringWithoutSpaces.split(',');
    const emailCount = values.length + listValue.length;
    if (emailCount <= 10) {
      const validEmails = listValue.reduce((acc, email) => {
        const isExistedEmail = values.some((item) => item.value === email);
        if (regexEmail.test(email) && !isExistedEmail) {
          acc.push({ label: email, value: email });
        }
        return acc;
      }, []);
      setValues([...values, ...validEmails]);
      setInputValue('');
      setAlertMessage('');
    } else {
      setAlertMessage(i18next.t('form.validate.emailLimit'));
    }
  };
  useEffect(() => {
    const listValue = values.map((item) => item.value);
    onChange(listValue);
  }, [JSON.stringify(values)]);

  return (
    <Field isRequired name={name} label={label} {...props}>
      {({ fieldProps }) => (
        <>
          <Select
            {...fieldProps}
            className={classes.select}
            value={values}
            onChange={setValues}
            inputValue={inputValue}
            onInputChange={(value) => {
              setInputValue(value);
              setAlertMessage('');
            }}
            placeholder={placeholder}
            components={{ DropdownIndicator: DropdownIndicatorNone }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleChangeValue();
              }
            }}
            onBlur={handleChangeValue}
            name={name}
            isMulti
            menuIsOpen={false}
          />
          {alertMessage ? (
            <ErrorMessage>{alertMessage}</ErrorMessage>
          ) : (
            <HelperMessage>
              {i18next.t('form.validate.emailHelperTxt')}
            </HelperMessage>
          )}
        </>
      )}
    </Field>
  );
};

export { MultipleEmailTextField, DropdownIndicatorNone };
