import { ThemeProvider } from '@material-ui/styles';
import { AuthProvider } from '@root/components/AuthPages/AuthProvider';
import withBaseUrlLanguageDetector from '@root/hoc/withBaseUrlLanguageDetector';
import theme from '@root/theme';
import '@root/theme/styles.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MessageSnackbar from '../components/common/MessageSnackbar';
import '../translations/i18n';
import AppWithRouterAccess from './AppWithRouterAccess';
import MaintenancePage from '../components/MaintenancePage'; 
import './index.css';

const isMaintenanceMode = process.env.IS_MAINTENANCE_MODE === 'true'; // Set this to control maintenance mode
const App = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <Router>
        {isMaintenanceMode ? <MaintenancePage /> : <AppWithRouterAccess />}
        <MessageSnackbar />
      </Router>
    </ThemeProvider>
  </AuthProvider>
);

export default withBaseUrlLanguageDetector(App);
