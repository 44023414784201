import React, { forwardRef } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Select, { components } from '@atlaskit/select';
import { Checkbox } from '@atlaskit/checkbox';
import Badge from '@atlaskit/badge';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { isSiteDisable } from '@root/utils';

const NUMBER_OF_SITE_TO_SHOW = 2;

const useStyle = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  optionDisable: {
    minHeight: 30,
    display: 'flex',
    fontWeight: 'normal',
    color: '#a5adba',
    width: '100%',
    cursor: 'not-allowed',
    alignItems: 'center',
    '& .organization-name': {
      color: 'inherit',
      fontWeight: 'normal',
      marginLeft: 3,
    },
  },
  options: {
    minHeight: 32,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#1E1F21',
    '& .organization-name': {
      color: '#6d6d6d',
      fontWeight: 'normal',
      marginLeft: 3,
    },
  },
  menuSelect: {
    zIndex: 2,
    position: 'relative',
  },
  optionRightWarning: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-all',
    paddingRight: 15,
    width: 'max-content',
  },
  optionRight: {
    paddingRight: 15,
    width: 'max-content',
  },
  optionLabel: {
    color: '#45526c',
  },
  optionName: {
    display: 'inline-block',
    marginLeft: 3,
    fontWeight: 600,
  },
  textOrgName: {},
  buttonText: {
    display: 'flex',
    alignItems: 'center',
  },
  selectSite: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 4,
    width: '100%',
  },
  licenseDisable: {
    display: 'flex',
  },
  value: {
    display: 'flex',
    '& .organization-name': {
      marginLeft: 3,
    },
  },
  selected: {
    backgroundColor: '#ffffff !important',
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: '#f4f5f7 !important',
      boxShadow: 'inset 2px 0px 0px #0052cc !important',
    },
  },
}));
const SiteMultipleSelectField = forwardRef(
  (
    {
      value,
      dataList,
      isOptionDisabled,
      onChange = () => {},
      options,
      onMenuClose,
      ...props
    },
    ref,
  ) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const CustomIssueOption = ({ data, ...props }) => {
      const lastSpaceIndex = data.label.lastIndexOf(' ');
      const organizationName = data.label.slice(0, lastSpaceIndex);
      const siteName = data.label.slice(lastSpaceIndex + 1);
      const site = dataList.find((i) => i.siteId === data.value);
      const isDisable = isSiteDisable(site);
      const { isSelected } = props;
      return (
        <components.Option
          {...props}
          style={{ display: 'none' }}
          className={isSelected ? classes.selected : ''}
        >
          <div className={isDisable ? classes.optionDisable : classes.options}>
            {isDisable ? (
              <div className={classes.optionContainer}>
                <Checkbox isDisabled />
                <div className={classes.optionRightWarning}>
                  <WarningIcon primaryColor='#E5686F' />
                  <div className={classes.selectSite}>
                    <div className={classes.buttonText}>
                      <div>
                        <span>{t('activity.organizationLabel')}:</span>
                        <span className='organization-name'>
                          {organizationName}
                        </span>
                      </div>
                    </div>
                    <div style={{ color: '#E5686F', fontSize: 10 }}>
                      {t('common.licenseOutdate')}
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: 14 }}>
                  <span>{t('activity.siteLabel')}:</span>
                  <span style={{ marginLeft: 3 }}>{siteName}</span>
                </div>
              </div>
            ) : (
              <div className={classes.optionContainer}>
                <Checkbox isChecked={isSelected} />
                <div className={classes.buttonText}>
                  <div className={`${classes.optionRight}`}>
                    <span className={classes.optionLabel}>
                      {t('activity.organizationLabel')}:
                    </span>
                    <span className='organization-name'>
                      {organizationName}
                    </span>
                  </div>
                  <div>
                    <span className={classes.optionLabel}>
                      {t('activity.siteLabel')}:
                    </span>
                    <span className={classes.optionName}>{siteName}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </components.Option>
      );
    };

    const CustomIssueValueOption = ({ data, ...props }) => {
      const lastSpaceIndex = data.label.lastIndexOf(' ');
      const organizationName = data.label.slice(0, lastSpaceIndex);
      const siteName = data.label.slice(lastSpaceIndex + 1);
      const index = value.findIndex((item) => item.value === data.value);
      if (index > NUMBER_OF_SITE_TO_SHOW) {
        return <></>;
        // eslint-disable-next-line no-else-return
      } else if (index === NUMBER_OF_SITE_TO_SHOW) {
        return (
          <Badge>
            <div style={{ fontWeight: 600 }}>
              +{value.length - NUMBER_OF_SITE_TO_SHOW}
            </div>
          </Badge>
        );
      } else {
        return (
          <components.MultiValue {...props}>
            <div className={classes.value}>
              <div style={{ marginRight: 8 }}>
                <span className={classes.optionLabel}>
                  {t('activity.organizationLabel')}:
                </span>
                <span className='organization-name'>{organizationName}</span>
              </div>
              <div>
                <span className={classes.optionLabel}>
                  {t('activity.siteLabel')}:
                </span>
                <span className={classes.optionName}>{siteName}</span>
              </div>
            </div>
          </components.MultiValue>
        );
      }
    };

    return (
      <div className={classes.menuSelect}>
        <Select
          value={value}
          isOptionDisabled={isOptionDisabled}
          components={{
            Option: CustomIssueOption,
            MultiValue: CustomIssueValueOption,
          }}
          isMulti
          onChange={onChange}
          filterOption={(option, inputValue) =>
            option.label.includes(inputValue)
          }
          options={options}
          placeholder={t('activity.selectSitePlaceholder')}
          loadingMessage={() => t('userManagement.loadingText')}
          noOptionsMessage={() => t('userManagement.noOptions')}
          openMenuOnClick
          onMenuClose={onMenuClose}
          openMenuOnFocus
          closeMenuOnSelect={false}
          styles={{
            option: (styles) => ({
              ...styles,
              whiteSpace: 'nowrap',
            }),
          }}
          hideSelectedOptions={false}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
export { SiteMultipleSelectField, NUMBER_OF_SITE_TO_SHOW };
